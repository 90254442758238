import { FontAwesome5 } from '@expo/vector-icons';
import styled from 'styled-components/native';
import { BUTTON_BORDER_WIDTH, BUTTON_HEIGHT, BUTTON_ICON_SIZE, BUTTTON_ICON_MARGIN, COLORS, FONT_BOLD, SMALL_MARGIN, STANDARD_FONT_SIZE } from '../constants';

const Touchable = styled.TouchableOpacity`
  max-width: 500px;
  width: 100%;
  height: ${BUTTON_HEIGHT}px;
  border-radius: ${BUTTON_HEIGHT / 2}px;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const Text = styled.Text`
  font-family: ${FONT_BOLD};
  line-height: ${STANDARD_FONT_SIZE}px;
  font-size: ${STANDARD_FONT_SIZE}px;
`;

const Icon = styled(FontAwesome5)`
  margin-right: ${BUTTTON_ICON_MARGIN}px;
`;

const PrimaryTouchable = styled(Touchable)`
  background-color: ${COLORS.DARK};
`;

const PrimaryText = styled(Text)`
  color: ${COLORS.BACKGROUND};
`;

const SecondaryTouchable = styled(Touchable)`
  border-color: ${COLORS.DARK};
  border-width: ${BUTTON_BORDER_WIDTH}px;
`;

const SecondaryText = styled(Text)`
  color: ${COLORS.DARK};
`;

const Button = ({
  onPress,
  style,
  text,
  icon,
  iconStyle,
  iconSize,
  iconColor,
  TouchableComponent,
  TextComponent
}) => {
  return (
    <TouchableComponent onPress={onPress} style={style}>
      {!icon ? null : <Icon name={icon} size={iconSize || BUTTON_ICON_SIZE} color={iconColor} solid style={iconStyle} />}
      <TextComponent>
        {text}
      </TextComponent>
    </TouchableComponent>
  );
};

export const PrimaryButton = (props) => {
  return (
    <Button
      TouchableComponent={PrimaryTouchable}
      TextComponent={PrimaryText}
      iconColor={COLORS.BACKGROUND}
      {...props}
    />
  );
};

export const SecondaryButton = (props) => {
  return (
    <Button
      TouchableComponent={SecondaryTouchable}
      TextComponent={SecondaryText}
      iconColor={COLORS.DARK}
      {...props}
    />
  );
};