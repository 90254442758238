import MidiWriter from 'midi-writer-js';
import { BEATBOT_OSAMP, BEATBOT_WINDOW_SIZE, MIDI_BPM, MIDI_DRUM_START } from '../constants';
import { framesToSamples } from '../util/conversion';

export const buildMidiBytes = (
  beatStarts,
  beatEnds,
  classifications,
  sampleRate,
) => {

  const midiTrack = new MidiWriter.Track();
  midiTrack.setTempo(MIDI_BPM);

  for (let i = 0; i < beatStarts.length; i++) {
    const beatStart = beatStarts[i];
    const beatEnd = beatEnds[i];
    const classification = classifications[i];

    const startTick = framesToTicks(beatStart, sampleRate, BEATBOT_WINDOW_SIZE, BEATBOT_OSAMP, MIDI_BPM);
    const endTick = framesToTicks(beatEnd, sampleRate, BEATBOT_WINDOW_SIZE, BEATBOT_OSAMP, MIDI_BPM);
    const tickDuration = endTick - startTick;

    const midiPitch = classification + MIDI_DRUM_START;
    const midiNote = new MidiWriter.NoteEvent({
      startTick,
      pitch: midiPitch,
      duration: 'T' + tickDuration,
    });
    midiTrack.addEvent(midiNote);
  }

  const writer = new MidiWriter.Writer([midiTrack]);
  return writer.buildFile().buffer;
};

const framesToTicks = (
  frames,
  sampleRate,
  windowSize,
  osamp,
  bpm,
) => {
  const samples = framesToSamples(frames, windowSize, osamp);
  const ms = samplesToMs(samples, sampleRate);
  const ticks = msToTicks(ms, bpm);
  return ticks;
};

const samplesToMs = (samples, sampleRate) => {
  return samples / sampleRate * 1000;
};

const msToTicks = (ms, bpm) => {
  const tempo = Math.round((60 * 1000000) / bpm);
  const ticksPerBeat = 128;
  const seconds = ms / 1000;
  const ticks = Math.round(seconds / (tempo * 1e-6 / ticksPerBeat));
  return ticks;
};