import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { COLORS, FONT_BOLD, LARGE_FONT_SIZE, SMALL_MARGIN, STANDARD_MARGIN, TITLE_FONT_SIZE } from '../constants';
import { hideConfirmModal } from '../redux/modalsSlice';
import { PrimaryButton, SecondaryButton } from './Button';
import Modal from './Modal';

const Container = styled.View`
  padding: ${STANDARD_MARGIN}px;
`;

const Text = styled.Text`
  font-family: ${FONT_BOLD};
  font-size: ${TITLE_FONT_SIZE}px;
  line-height: ${TITLE_FONT_SIZE * 1.5}px;
  margin-bottom: ${SMALL_MARGIN}px;
  color: ${COLORS.DARK};
`;

const Buttons = styled.View`
  flex-direction: row;
`;

const CancelButton = styled(SecondaryButton)`
  flex: 1;
  margin-right: ${SMALL_MARGIN / 2}px;
`;

const ConfirmButton = styled(PrimaryButton)`
  flex: 1;
  margin-left: ${SMALL_MARGIN / 2}px;
`;

const ModalConfirm = () => {

  const {
    isVisible,
    text,
    confirmText,
    onConfirm,
    onCancel,
  } = useSelector(state => state.modals.confirm);

  const dispatch = useDispatch()

  const onPressConfirm = () => {
    dispatch(hideConfirmModal());
    onConfirm();
  };

  const onPressCancel = () => {
    dispatch(hideConfirmModal());
    onCancel();
  };

  return (
    <Modal
      isVisible={isVisible}
      close={onPressCancel}
    >
      <Container>
        <Text>
          {text}
        </Text>
        <Buttons>
          <CancelButton
            text='Cancel'
            onPress={onPressCancel}
          />
          <ConfirmButton
            text={confirmText}
            onPress={onPressConfirm}
          />
        </Buttons>
      </Container>
    </Modal>
  )
};

export default ModalConfirm;
