import { useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { Loading_WAVE_VISUAL_COLORS, STANDARD_MARGIN } from '../constants';
import Modal from './Modal';
import WaveVisual from './WaveVisual';

const StyledModal = styled(Modal)`
  max-width: 100%;
  height: 100%;
  background: #ffffff00;
  border-radius: 0;
  justify-content: center;
`;

const LoadingScreen = () => {

  const isLoading = useSelector(state => state.loading.isLoading);

  return (
    <StyledModal
      isVisible={isLoading}
      close={() => null}
      padding={0}
    >
      <WaveVisual 
        isAnimating={true}
        lineColors={Loading_WAVE_VISUAL_COLORS}
      />
    </StyledModal>
  );
};

export default LoadingScreen;