import { FontAwesome5 } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import { COLORS, HEADER_ICON_SIZE, STANDARD_MARGIN } from '../constants';
import { navigateBack } from '../util/navigate';

const Container = styled.View`
  padding-horizontal: ${STANDARD_MARGIN}px;
  padding-vertical: 10px;
`;

const BackButton = ({ callback }) => {
  
  const onPress = () => {
    if (callback) {
      callback();
    }
    navigateBack();
  };

  return (
    <TouchableOpacity onPress={onPress}>
      <Container>
        <FontAwesome5 name='arrow-left' color={COLORS.DARK} size={HEADER_ICON_SIZE} solid />
      </Container>
    </TouchableOpacity>
  );
};

export default BackButton;