import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_TOKEN_ACTION_TYPE } from '../../constants';
import { apiPost } from './api';

export const logIn = createAsyncThunk(
  AUTH_TOKEN_ACTION_TYPE + 'logIn',
  ({ email, password }) => {
    try {
      return apiPost('/auth_token', { email, password });
    }
    catch (e) {
      return Promise.reject(e);
    }
  }
);
