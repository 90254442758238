import createModule from './wasm.js';

let Module;
export const loadWasm = async () => {
  Module = await createModule();
};

const cwrap = (...cwrapArgs) => (...fnCallArgs) => {
  return Module.ccall(...cwrapArgs, [...fnCallArgs]);
};

export const init = cwrap('beatbot_init', null, ['number', 'number']);
export const recordAudioSegment = cwrap('beatbot_record_audio_segment', null, ['number', 'number']);
export const doneRecording = cwrap('beatbot_done_recording', null, []);
export const buildOutput = cwrap('beatbot_build_output', null, ['number', 'number', 'number', 'number']);

export const getAudioDataSize = cwrap('beatbot_get_audio_data_size', 'number', []);
export const getMelDbSum = cwrap('beatbot_get_mel_db_sum', 'number', ['number']);

export const getBeatStarts = cwrap('beatbot_get_beat_starts', 'number', []);
export const getBeatEnds = cwrap('beatbot_get_beat_ends', 'number', []);

export const getLikelyNumPeaks = cwrap('beatbot_get_likely_num_peaks', 'number', []);
export const getNumPeaksLikelihoods = cwrap('beatbot_get_num_peaks_likelihoods', 'number', []);
export const getNumPeaksSize = cwrap('beatbot_get_num_peaks_size', 'number', []);

export const getLikelyNumClusters = cwrap('beatbot_get_likely_num_clusters', 'number', []);
export const getNumClustersLikelihoods = cwrap('beatbot_get_num_clusters_likelihoods', 'number', []);
export const getNumClustersSize = cwrap('beatbot_get_num_clusters_size', 'number', []);

export const getClassifications = cwrap('beatbot_get_classifications', 'number', []);

export const getOutputTrack = cwrap('beatbot_get_output_track', 'number', []);
export const getOutputTrackLength = cwrap('beatbot_get_output_track_length', 'number', []);

export const setNumPeaks = cwrap('beatbot_set_num_peaks', null, ['number']);
export const setNumClusters = cwrap('beatbot_set_num_clusters', null, ['number']);

export const bufferToPointer = (buffer) => {
  const pointer = Module._malloc(buffer.byteLength);
  const heap = new Uint8Array(Module.HEAPU8.buffer, pointer, buffer.byteLength);
  heap.set(new Uint8Array(buffer));
  const free = () => Module._free(pointer);
  return { address: pointer, free };
};

export const buffersToPointerToPointers = (buffers) => {
  const pointerSize = 4;
  const pointers = buffers.map((buffer) => bufferToPointer(buffer));
  const pointer = Module._malloc(pointerSize * buffers.length);
  for (let i = 0; i < buffers.length; i++) {
    Module.setValue(pointer + i * pointerSize, pointers[i].address, '*');
  }
  const free = () => {
    pointers.forEach((pointer) => pointer.free());
    Module._free(pointer);
  };
  return { address: pointer, free };
};
  
export const int16PointerToArray = (pointer, numElements) => {
  return new Int16Array(Module.HEAP16.buffer, pointer, numElements);
};

export const int32PointerToArray = (pointer, numElements) => {
  return new Int32Array(Module.HEAP32.buffer, pointer, numElements);
};

export const doublePointerToArray = (pointer, numElements) => {
  return new Float64Array(Module.HEAPF64.buffer, pointer, numElements);
};
