import { FontAwesome5 } from '@expo/vector-icons';
import { useState } from 'react';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import { COLORS, HEADER_ICON_SIZE, STANDARD_MARGIN } from '../constants';
import ModalOptions from './ModalOptions';

const Container = styled.View`
  padding-horizontal: ${STANDARD_MARGIN}px;
  padding-vertical: 10px;
  margin-right: 3px;
`;

const OverflowMenu = ({ options }) => {
  
  const [isVisible, setIsVisible] = useState(false);

  return (
    <>
      <TouchableOpacity onPress={() => setIsVisible(true)}>
        <Container>
          <FontAwesome5
            name='ellipsis-v'
            color={COLORS.DARK}
            size={HEADER_ICON_SIZE}
            solid
          />
        </Container>
      </TouchableOpacity>
      <ModalOptions 
        options={options}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
      />
    </>
  );
};

export default OverflowMenu;

