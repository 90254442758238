import { createAsyncThunk } from '@reduxjs/toolkit';
import { FEEDBACK_ACTION_TYPE } from '../../constants';
import { Platform } from 'react-native';
import { apiPost } from './api';

export const sendFeedback = createAsyncThunk(
  FEEDBACK_ACTION_TYPE + 'sendFeedback',
  (message, { getState }) => {
    try {
      const deviceId = getState().user.deviceId;
      const platform = Platform.OS;
      return apiPost('/feedback', {
        message,
        deviceId,
        platform
      });
    }
    catch (e) {
      return Promise.reject(e);
    }
  }
);