import AsyncStorage from '@react-native-async-storage/async-storage';
import LZString from 'lz-string';

export const save = async (key, object) => {
  const string = JSON.stringify(object);
  const compressed = LZString.compressToEncodedURIComponent(string);
  await AsyncStorage.setItem(key, compressed);
};

export const load = async (key) => {
  const compressed = await AsyncStorage.getItem(key);
  if (compressed === null) {
    return null;
  }
  const string = LZString.decompressFromEncodedURIComponent(compressed);
  return JSON.parse(string);
};

export const loadAll = (keys) => {
  return Promise.all(keys.map(load));
};