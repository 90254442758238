import { useState, useMemo } from 'react';
import styled from 'styled-components/native';
import { AUDIO_VISUAL_LINE_MARGIN, AUDIO_VISUAL_LINE_WIDTH, COLORS, FONT, SMALL_MARGIN, STANDARD_FONT_SIZE, STANDARD_MARGIN } from '../../constants';
import { formatSeconds } from '../../util/stringFormatter';
import AudioVisualCursor from './AudioVisualCursor';

const Container = styled.View`
  position: relative;
  width: 100%;
  margin-vertical: ${STANDARD_MARGIN}px;
  flex: 1;
  max-height: 280px;
`;

const Timestamps = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-horizontal: ${SMALL_MARGIN}px;
  margin-bottom: ${SMALL_MARGIN}px;
`;

const Timestamp = styled.Text`
  font-family: ${FONT};
  font-size: ${STANDARD_FONT_SIZE}px;
  line-height: ${STANDARD_FONT_SIZE}px;
  color: ${COLORS.DARK};
`;

const Lines = styled.View`
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const LinesInner = styled.View`
  flex: 1;
  align-items: center;
  justify-content: space-around;
  flex-direction: row;
  width: ${props => props.width}px;
`;

const Line = styled.View`
  width: ${AUDIO_VISUAL_LINE_WIDTH}px;
  min-height: ${AUDIO_VISUAL_LINE_WIDTH * 2}px;
  border-radius: ${AUDIO_VISUAL_LINE_WIDTH / 2}px;
  background-color: ${props => props.color};
  height: ${props => props.height}px;
`;

const getLineHeight = (volume, numLines, index) => {
  const startIndex = Math.floor(index / numLines * (volume.length - 1));
  const endIndex = Math.floor((index + 1) / numLines * (volume.length - 1));
  let sum = 0;
  for (let i = startIndex; i <= endIndex; i++) {
    sum += volume[i];
  }
  return sum;
};

const updateLineColors = (colors, beatStart, beatEnd, numLines, classification, volume) => {
  const colorIndex = classification % COLORS.HIGHLIGHTS.length;
  const color = COLORS.HIGHLIGHTS[colorIndex];
  const lineStartIndex = Math.ceil(beatStart / (volume.length - 1) * (numLines - 1));
  let lineEndIndex = Math.floor(beatEnd / (volume.length - 1) * (numLines - 1));
  lineEndIndex = Math.max(lineEndIndex, lineStartIndex);
  for (let i = lineStartIndex; i <= lineEndIndex; i++) {
    colors[i] = color;
  }
};

const AudioVisual = ({
  volume,
  inputDuration,
  beatStarts,
  beatEnds,
  classifications,
  isInputPlaying,
  isOutputPlaying,
}) => {

  const timestamps = [];
  for (let i = 0; i < 4; i++) {
    const seconds = Math.round(inputDuration / 3 * i);
    timestamps.push(<Timestamp key={`timestamp-${i}`}>{formatSeconds(seconds)}</Timestamp>);
  }

  const [dimensions, setDimensions] = useState(null);

  const onLayoutLines = (event) => {
    const { width, height } = event.nativeEvent.layout;
    setDimensions({ width, height });
  };

  const lines = useMemo(() => {
    if (!dimensions) {
      return null;
    }
    const numLines = Math.floor((dimensions.width - AUDIO_VISUAL_LINE_MARGIN) / (AUDIO_VISUAL_LINE_WIDTH + AUDIO_VISUAL_LINE_MARGIN));
    const innerWidth = numLines * AUDIO_VISUAL_LINE_WIDTH + (numLines + 1) * AUDIO_VISUAL_LINE_MARGIN;
    const lineHeights = [];
    let maxLineHeight = -Infinity;
    let minLineHeight = Infinity;
    for (let i = 0; i < numLines; i++) {
      const lineHeight = getLineHeight(volume, numLines, i);
      maxLineHeight = Math.max(maxLineHeight, lineHeight);
      minLineHeight = Math.min(minLineHeight, lineHeight);
      lineHeights.push(lineHeight);
    }
    const colors = [];
    for (let i = 0; i < numLines; i++) {
      colors.push(COLORS.LIGHT);
    }
    for (let i = 0; i < beatStarts.length; i++) {
      updateLineColors(colors, beatStarts[i], beatEnds[i], numLines, classifications[i], volume);
    }
    const lines = [];
    for (let i = 0; i < numLines; i++) {
      const pixelHeight = (lineHeights[i] - minLineHeight) / (maxLineHeight - minLineHeight) * dimensions.height;
      lines.push(<Line key={`line-${i}`} height={pixelHeight} color={colors[i]} />); 
    }
    return <LinesInner width={innerWidth}>{lines}</LinesInner>;
  }, [dimensions, volume, beatStarts, beatEnds, classifications]);

  return (
    <Container>
      <AudioVisualCursor
        inputDuration={inputDuration}
        isInputPlaying={isInputPlaying}
        isOutputPlaying={isOutputPlaying}
      />
      <Timestamps>
        {timestamps}
      </Timestamps>
      <Lines onLayout={onLayoutLines}>
        {lines}
      </Lines>
    </Container>
  )
};

export default AudioVisual;