import { BalooTamma2_400Regular, BalooTamma2_700Bold } from '@expo-google-fonts/baloo-tamma-2';
import { Coiny_400Regular } from '@expo-google-fonts/coiny';
import { FontAwesome5 } from '@expo/vector-icons';
import AppLoading from 'expo-app-loading';
import { useFonts } from 'expo-font';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadWasm } from '../../beatbot/wasmApi';
import { syncRecordingsWithCloud } from '../../recordings/recordingSync';
import { loadState as loadRecordingsState } from '../../redux/recordingsSlice';
import { loadState as loadUserState } from '../../redux/userSlice';
import Navigator from '../Navigator/Navigator';

const PreLoader = () => {

  const dispatch = useDispatch();
  const areRecordingsLoaded = useSelector(state => state.recordings.isStateLoaded);
  const isUserLoaded = useSelector(state => state.user.isStateLoaded);
  useEffect(() => {
    (async () => {
      await dispatch(loadUserState());
      await dispatch(loadRecordingsState());
      await syncRecordingsWithCloud();      
    })();
  }, []);

  const [wasmLoaded, setWasmLoaded] = useState(false);
  useEffect(() => {
    loadWasm().then(() => setWasmLoaded(true));
  }, []);

  const [fontsLoaded] = useFonts({
    ...FontAwesome5.font,
    Coiny_400Regular,
    BalooTamma2_700Bold,
    BalooTamma2_400Regular
  });

  if (
    !areRecordingsLoaded ||
    !isUserLoaded ||
    !wasmLoaded ||
    !fontsLoaded
  ) {
    return <AppLoading />;
  }

  return <Navigator />;
};

export default PreLoader;

