import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { defaultDrumMap } from '../audio/drumSounds/drumSounds';
import * as recorder from '../audio/recorder/recorder';
import * as beatbot from '../beatbot/beatbot';
import { DEFAULT_RECORDING_NAME } from '../constants';
import { createRecordingOnCloud, deleteRecordingOnCloud, updateRecordingOnCloud } from '../redux/apiActions/recordingActions';
import { createRecordingInMemory, deleteRecordingInMemory, selectRecording, updateRecordingInMemory } from '../redux/recordingsSlice';
import { dispatch, getState } from '../redux/store';
import { createRecordingLocally, deleteRecordingLocally, updateRecordingLocally } from './recordingStorage';

export const createRecording = async () => {
  const recording = {
    storageId: uuidv4(),
    isDeleted: false,
    isBackedUp: false,
    name: DEFAULT_RECORDING_NAME,
    createdEpoch: new Date().valueOf(),
    modifiedEpoch: new Date().valueOf(),
    inputAudio: {
      rate: recorder.getSampleRate(),
      channels: recorder.getNumChannels(),
      data: Array.from(recorder.getRecordedAudio()),
      duration: recorder.getDuration(),
    },
    drumMap: defaultDrumMap,
    algorithmVersion: '0.0.1',
    beatbotData: beatbot.getBeatbotData(defaultDrumMap),
  };
  await createRecordingLocally(recording);
  dispatch(createRecordingInMemory(recording));
  dispatch(createRecordingOnCloud(recording));
};

export const selectRecordingById = (storageId) => {
  const recordings = getState().recordings.recordings;
  const index = _.findIndex(recordings, { storageId });
  const recording = recordings[index];
  beatbot.init(recording.inputAudio.rate, recording.inputAudio.channels);
  beatbot.record((new Int16Array(recording.inputAudio.data)).buffer)
  beatbot.doneRecording();
  beatbot.setNumPeaks(recording.beatbotData.chosenNumPeaks);
  beatbot.setNumClusters(recording.beatbotData.chosenNumClusters);
  const update = {
    beatbotData: beatbot.getBeatbotData(
      recording.drumMap,
      recording.beatbotData.chosenNumPeaks,
      recording.beatbotData.chosenNumClusters,
    )
  };
  dispatch(updateRecordingInMemory({ index, update }));
  dispatch(selectRecording({ index }));
};

export const saveSelected = async () => {
  const index = getState().recordings.focusedRecordingIndex;
  const recording = getState().recordings.recordings[index];
  await updateRecordingLocally(recording);
  dispatch(updateRecordingOnCloud(recording));
};

export const setSelectedNumPeaks = async (numPeaks) => {
  beatbot.setNumPeaks(numPeaks);
  const index = getState().recordings.focusedRecordingIndex;
  const recording = getState().recordings.recordings[index];
  const update = {
    isBackedUp: false,
    modifiedEpoch: new Date().valueOf(),
    beatbotData: beatbot.getBeatbotData(
      recording.drumMap,
      numPeaks,
    )
  };
  dispatch(updateRecordingInMemory({ index, update }));
};

export const setSelectedNumClusters = async (numClusters) => {
  beatbot.setNumClusters(numClusters);
  const index = getState().recordings.focusedRecordingIndex;
  const recording = getState().recordings.recordings[index];
  const update = {
    isBackedUp: false,
    modifiedEpoch: new Date().valueOf(),
    beatbotData: beatbot.getBeatbotData(
      recording.drumMap,
      recording.beatbotData.chosenNumPeaks,
      numClusters
    )
  };
  dispatch(updateRecordingInMemory({ index, update }));
};

export const setSelectedDrumMap = async (drumMap) => {
  const index = getState().recordings.focusedRecordingIndex;
  const recording = getState().recordings.recordings[index];
  const update = {
    isBackedUp: false,
    modifiedEpoch: new Date().valueOf(),
    drumMap,
    beatbotData: beatbot.getBeatbotData(
      drumMap,
      recording.beatbotData.chosenNumPeaks,
      recording.beatbotData.chosenNumClusters,
    )
  };
  dispatch(updateRecordingInMemory({ index, update }));
};

export const renameSelected = async (name) => {
  const index = getState().recordings.focusedRecordingIndex;
  const update = {
    isBackedUp: false,
    modifiedEpoch: new Date().valueOf(),
    name,
  };
  dispatch(updateRecordingInMemory({ index, update }));
};

export const deleteSelected = async () => {
  const index = getState().recordings.focusedRecordingIndex;
  const recording = getState().recordings.recordings[index];
  const deletedRecording = {
    _id: recording._id,
    storageId: recording.storageId,
    isDeleted: true,
    isBackedUp: false,
    modifiedEpoch: new Date().valueOf(),
  };
  await deleteRecordingLocally(deletedRecording);
  dispatch(deleteRecordingInMemory({ index, deletedRecording }));
  dispatch(deleteRecordingOnCloud(deletedRecording));
};
