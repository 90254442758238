import moment from 'moment';

export const formatSeconds = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds - (minutes * 60);
  let minutesString = minutes.toString();
  let secondsString = remainingSeconds.toString();
  if (minutesString.length < 2) {
    minutesString = '0' + minutesString;
  }
  if (secondsString.length < 2) {
    secondsString = '0' + secondsString;
  }
  return `${minutesString}:${secondsString}`;
};

export const formatDate = (epoch) => {
  return moment(epoch).format('MMM D, YYYY');
};