import { FontAwesome5 } from '@expo/vector-icons';
import { useEffect, useRef } from 'react';
import { Animated, Easing } from 'react-native';
import styled from 'styled-components/native';
import WaveVisual from '../../components/WaveVisual';
import { ANIMATION_USE_NATIVE_DRIVER, COLORS, SIGN_IN_RECORD_ICON_SIZE, SIGN_IN_WAVE_VISUAL_OPACITIES } from '../../constants';

const WaveContainer = styled.View`
  width: 100%;
  position: relative;
  align-items: center;
`;

const WaveIcon = styled(FontAwesome5)`
  position: absolute;
  top: -14px;
`;

const AnimatedWaveIcon = Animated.createAnimatedComponent(WaveIcon);

let rotation = '0deg';

const SignInVisual = ({ color }) => {

  const rotationAnimation = useRef(new Animated.Value(0)).current;
  useEffect(() => {
    if (rotationAnimation) {
      Animated.loop(
        Animated.sequence([
          Animated.timing(rotationAnimation, {
            toValue: 1,
            duration: 20000,
            easing: Easing.linear,
            useNativeDriver: ANIMATION_USE_NATIVE_DRIVER
          }),
          Animated.timing(rotationAnimation, {
            toValue: 0,
            duration: 0,
            useNativeDriver: ANIMATION_USE_NATIVE_DRIVER
          })
        ])
      ).start();
    }
  }, [rotationAnimation]);
  if (rotationAnimation) {
    rotation = rotationAnimation.interpolate({
      inputRange: [0, 1],
      outputRange: ['0deg', '360deg']
    });
  }

  const lineColors = SIGN_IN_WAVE_VISUAL_OPACITIES.map((opacity) => color + opacity);
  return (
    <WaveContainer>
      <WaveVisual lineColors={lineColors} />
      <AnimatedWaveIcon
        name='compact-disc'
        size={SIGN_IN_RECORD_ICON_SIZE}
        color={COLORS.DARK}
        solid
        style={{ transform: [{ rotate: rotation }] }}
      />
    </WaveContainer>
  );
};

export default SignInVisual;