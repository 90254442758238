import { useSelector } from 'react-redux';
import ModalOptions from '../../components/ModalOptions';
import { EXPORT_FILE_TYPES } from '../../constants';
import { exportRecording } from '../../util/exporter';
import { selectFocusedRecording } from '../../redux/selectors';

const ExportModal = ({ isVisible, setIsVisible }) => {

  const recording = useSelector(selectFocusedRecording);

  return (
    <ModalOptions
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      options={[
        {
          text: 'Export midi data (.mid)',
          onPress: () => {
            exportRecording(recording, EXPORT_FILE_TYPES.MIDI);
          },
        },
        {
          text: 'Export audio (.wav)',
          onPress: () => {
            exportRecording(recording, EXPORT_FILE_TYPES.WAV);
          },
        },
      ]}
    />
  );
};

export default ExportModal;