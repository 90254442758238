import { useEffect } from 'react';
import styled from 'styled-components/native';
import WaveVisual from '../../components/WaveVisual';
import { SCREEN_OPTIONS, COLORS, PROCESSING_WAVE_VISUAL_COLORS, STANDARD_MARGIN, TITLE_FONT, TITLE_FONT_SIZE } from '../../constants';

const Container = styled.View`
  flex: 1;
  width: 100%;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  margin-bottom: ${2 * STANDARD_MARGIN}px;
`;

const EditorLoading = ({ navigation }) => {

  useEffect(() => {
    navigation.setOptions({
      title: 'Loading...',
      headerLeft: null,
    });
  }, []);

  return (
    <Container>
      <WaveVisual
        lineColors={PROCESSING_WAVE_VISUAL_COLORS}
      />
    </Container>
  )
};

export default EditorLoading;