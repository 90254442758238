import { FontAwesome5 } from '@expo/vector-icons';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import { COLORS, FONT, NAV_BAR_DATA, NAV_BAR_FONT_SIZE, NAV_BAR_HEIGHT, NAV_BAR_ICON_SIZE, STANDARD_MARGIN } from '../../constants';

const Anchor = styled.View`
  height: 0;
  width: 100%;
  position: relative;
  align-items: center;
`;

const Container = styled.View`
  position: absolute;
  top: -${NAV_BAR_HEIGHT + STANDARD_MARGIN}px;
  width: 100%;
  padding-horizontal: ${STANDARD_MARGIN}px;
  max-width: 400px;
`;

const Background = styled.View`
  background-color: ${COLORS.LIGHT};
  height: ${NAV_BAR_HEIGHT}px;
  border-radius: ${NAV_BAR_HEIGHT / 2}px;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-horizontal: 15px;
`;

const NavItem = styled.View`
  height: 60%;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  opacity: ${props => props.isSelected ? 1 : 0.5};
`;

const NavIcon = styled(FontAwesome5)`
  margin-bottom: 3px;
`;

const NavText = styled.Text`
  color: ${COLORS.DARK};
  font-family: ${FONT};
  font-size: ${NAV_BAR_FONT_SIZE}px;
`;

const NavigationBar = ({ state, navigation }) => {

  const onPress = (index) => () => {
    navigation.navigate({ name: state.routes[index].name });
  };

  return (
    <Anchor>
      <Container>
        <Background>
          {NAV_BAR_DATA.map((navItem, index) => (
            <TouchableOpacity 
              key={`nav-item-${index}`}
              onPress={onPress(index)}
              disabled={state.index === index}
            >
              <NavItem
                isSelected={state.index === index}
              >
                <NavIcon name={navItem.icon} size={NAV_BAR_ICON_SIZE} color={COLORS.DARK} solid style={navItem.iconStyle} />
                <NavText>
                  {navItem.name}
                </NavText>
              </NavItem>
            </TouchableOpacity>
          ))}
        </Background>
      </Container>
    </Anchor>
  );
};



export default NavigationBar;