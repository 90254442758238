import styled from 'styled-components/native';
import { Animated, Easing } from 'react-native';
import { useEffect, useRef, useState } from 'react';
import { ANIMATION_USE_NATIVE_DRIVER, COLORS, SMALL_MARGIN, STANDARD_FONT_SIZE } from '../../constants';

const Container = styled.View`
  position: absolute;
  top: ${STANDARD_FONT_SIZE + SMALL_MARGIN}px;
  bottom: 0;
  width: 4px;
  border-radius: 2px;
  background-color: ${COLORS.DARK};
  z-index: 1;
  opacity: ${props => props.isVisible ? 1 : 0};
`;

const AnimatedContainer = Animated.createAnimatedComponent(Container);

const AudioVisualCursor = ({
  isInputPlaying,
  isOutputPlaying,
  inputDuration,
}) => {

  const [animation, setAnimation] = useState(null);

  const animatedLeft = useRef(new Animated.Value(0)).current;
  const animatedLeftPercent = animatedLeft ? animatedLeft.interpolate({
    inputRange: [0, 1],
    outputRange: ['0%', '100%']
  }) : '0%';

  const startAnimation = () => {
    // Note: duration should always be input duration, even when playing output
    const duration = inputDuration;
    const animation = Animated.loop(
      Animated.sequence([
        Animated.timing(animatedLeft, {
          toValue: 0,
          duration: 0,
          useNativeDriver: ANIMATION_USE_NATIVE_DRIVER,
        }),
        Animated.timing(animatedLeft, {
          toValue: 1,
          duration: duration * 1000,
          useNativeDriver: ANIMATION_USE_NATIVE_DRIVER,
          easing: Easing.linear,
        }),
      ])
    );
    animation.start();
    setAnimation(animation);
  };

  const stopAnimation = () => {
    if (animation) {
      animation.stop();
    }
  };

  useEffect(() => {
    if (isInputPlaying || isOutputPlaying) {
      startAnimation();
    } else {
      stopAnimation();
    }
  }, [isInputPlaying, isOutputPlaying]);


  return (
    <AnimatedContainer
      isVisible={isInputPlaying || isOutputPlaying}
      style={{ left: animatedLeftPercent }}
    />
  );
};

export default AudioVisualCursor;