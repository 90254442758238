const config = {};

if (process.env.NODE_ENV === 'production') {
  config.API_BASE = 'https://beatbot-backend.herokuapp.com/api';
}

else {
  config.API_BASE = 'http://localhost:3001/api';
}

export default config;