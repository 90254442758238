import styled from 'styled-components/native';
import { COLORS, FONT_BOLD, LARGE_FONT_SIZE, SMALL_MARGIN } from '../constants';
import Modal from './Modal';

const Options = styled.View`
  padding-vertical: ${SMALL_MARGIN / 2}px;
`;

const OptionContainer = styled.View`
`;

const Option = styled.TouchableOpacity`
`;

const OptionText = styled.Text`
  font-size: ${LARGE_FONT_SIZE}px;
  line-height: ${LARGE_FONT_SIZE}px;
  font-family: ${FONT_BOLD};
  color: ${COLORS.DARK};
  text-align: center;
  padding: ${SMALL_MARGIN}px;
`;

const Divider = styled.View`
  background-color: ${COLORS.DARK};
  height: 1px;
  opacity: 0.2;
`;

const ModalOptions = ({
  options,
  isVisible,
  setIsVisible,
}) => {
  return (
    <Modal
      isVisible={isVisible}
      close={() => setIsVisible(false)}
    >
      <Options>
        {options.map((option, index) => {
          const onPress = () => {
            setIsVisible(false);
            option.onPress();
          };
          return (
            <OptionContainer key={`option-${index}`}>
              <Option onPress={onPress}>
                <OptionText>
                  {option.text}
                </OptionText>
              </Option>
              {index === (options.length - 1) ? null : <Divider />}
            </OptionContainer>
          );
        })}
      </Options>
    </Modal>
  );
};

export default ModalOptions;

