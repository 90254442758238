import { useEffect, useRef, useState } from 'react';
import { Animated, Easing } from 'react-native';
import Svg, { Path } from 'react-native-svg';
import styled from 'styled-components/native';
import { ANIMATION_USE_NATIVE_DRIVER, WAVE_VISUAL_AMPLITUDE, WAVE_VISUAL_LINE_THICKNESS } from '../constants';

const Container = styled.View`
  width: 100%;
  height: ${props => WAVE_VISUAL_LINE_THICKNESS * props.numLines + WAVE_VISUAL_AMPLITUDE}px;
  overflow: hidden;
`;

let animation;

const WaveVisual = ({
  lineColors,
  isAnimating,
  animationDuration = 3000,
  isAnimationForwards = true
}) => {

  const animatedMargin = useRef(new Animated.Value(0)).current;

  const [dimensions, setDimensions] = useState(null);

  useEffect(() => {
    if (dimensions) {
      if (isAnimating) {
        animation = Animated.loop(
          Animated.sequence([
            Animated.timing(animatedMargin, {
              toValue: isAnimationForwards ? 0 : -width,
              duration: 0,
              useNativeDriver: ANIMATION_USE_NATIVE_DRIVER,
            }),
            Animated.timing(animatedMargin, {
              toValue: isAnimationForwards ? -width : 0,
              duration: animationDuration,
              easing: Easing.linear,
              useNativeDriver: ANIMATION_USE_NATIVE_DRIVER
            })
          ])
        );
        animation.start();
      } else {
        if (animation) {
          animation.stop();
          Animated.timing(animatedMargin, {
            toValue: 0,
            duration: 0,
            useNativeDriver: ANIMATION_USE_NATIVE_DRIVER
          }).start();
        }
      }
    }
  }, [dimensions, isAnimating]);

  const onLayout = (event) => {
    const { width, height } = event.nativeEvent.layout;
    setDimensions({ width, height });
  };

  const width = dimensions?.width;
  const height = dimensions?.height;

  return (
    <Container numLines={lineColors.length} onLayout={onLayout}>
      <Animated.View style={{ marginLeft: animatedMargin }}>
        {!dimensions ? null : (
          <Svg width={width * 2} height={height}>
            {lineColors.map((color, index) => (
              <Path
                key={`wave-path-${index}`}
                d={`
                  M 0 ${WAVE_VISUAL_LINE_THICKNESS * index + WAVE_VISUAL_AMPLITUDE / 2}
                  q ${width / 4} ${WAVE_VISUAL_AMPLITUDE}, ${width / 2} 0
                  t ${width / 2} 0
                  q ${width / 4} ${WAVE_VISUAL_AMPLITUDE}, ${width / 2} 0
                  t ${width / 2} 0
                  v ${WAVE_VISUAL_LINE_THICKNESS}
                  q -${width / 4} -${WAVE_VISUAL_AMPLITUDE}, -${width / 2} 0
                  t -${width / 2} 0
                  q -${width / 4} -${WAVE_VISUAL_AMPLITUDE}, -${width / 2} 0
                  t -${width / 2} 0
                  z
                `}
                fill={color}
              />
            ))}
          </Svg>
        )}
      </Animated.View>
    </Container>
  );
};

export default WaveVisual;