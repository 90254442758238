import styled from 'styled-components/native';
import { PrimaryButton } from '../../components/Button';
import WaveVisual from '../../components/WaveVisual';
import { COLORS, LARGE_FONT_SIZE, LIBRARY_EMPTY_WAVE_VISUAL_COLORS, NAV_BAR_HEIGHT, RECORD_WAVE_VISUAL_COLORS, STANDARD_MARGIN, TITLE_FONT } from '../../constants';
import { navigate } from '../../util/navigate';

const Container = styled.View`
  align-items: center;
  flex: 1;
  width: 100%;
  margin-bottom: ${3 * STANDARD_MARGIN + NAV_BAR_HEIGHT}px;
  justify-content: space-between;
`;

const Message = styled.Text`
  color: ${COLORS.DARK};
  text-align: center;
  font-family: ${TITLE_FONT};
  font-size: ${LARGE_FONT_SIZE}px;
`;

const ButtonContainer = styled.View`
  padding-horizontal: ${STANDARD_MARGIN}px;
  width: 100%;
  align-items: center;
`;

const Button = styled(PrimaryButton)`
`;

const LibraryEmpty = () => {
  const onPress = () => {
    navigate('Record');
  };
  return (
    <Container>
      <Message>
        Your library is empty
      </Message>
      <WaveVisual isAnimating animationDuration={15000} lineColors={LIBRARY_EMPTY_WAVE_VISUAL_COLORS} />
      <ButtonContainer>
        <Button
          text='Make a recording' 
          onPress={onPress}
        />
      </ButtonContainer>
    </Container>
  );
};

export default LibraryEmpty;