import styled from 'styled-components/native';
import { COLORS, FONT_BOLD, STANDARD_FONT_SIZE, TEXT_INPUT_BORDER_WIDTH, TEXT_INPUT_HEIGHT, TEXT_INPUT_HORIZONTAL_PADDING, TEXT_INPUT_PLACEHOLDER_COLOR } from '../constants';

const Input = styled.TextInput`
  border-width: ${TEXT_INPUT_BORDER_WIDTH}px;
  border-color: ${COLORS.DARK};
  height: ${TEXT_INPUT_HEIGHT}px;
  border-radius: ${TEXT_INPUT_HEIGHT / 2}px;
  width: 100%;
  font-family: ${FONT_BOLD};
  font-size: ${STANDARD_FONT_SIZE}px;
  padding-horizontal: ${TEXT_INPUT_HORIZONTAL_PADDING}px;
  color: ${COLORS.DARK};
  max-width: 500px;
`;

const TextInput = ({ ...props }) => {
  return (
    <Input
      placeholderTextColor={TEXT_INPUT_PLACEHOLDER_COLOR}
      selectionColor={COLORS.LIGHT}
      {...props}
    />
  );
};

export default TextInput;