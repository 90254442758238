import { configureStore } from '@reduxjs/toolkit';
import loadingSlice from './loadingSlice';
import modalsReducer from './modalsSlice';
import recordingsReducer from './recordingsSlice';
import { setStore } from './store';
import userReducer from './userSlice';

export const initStore = () => {
  setStore(
    configureStore({
      middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
      reducer: {
        user: userReducer,
        recordings: recordingsReducer,
        modals: modalsReducer,
        loading: loadingSlice,
      }
    })
  );
};
