import { FontAwesome5 } from '@expo/vector-icons';
import { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/native';
import { makeSound } from '../../audio/player/player.web';
import { COLORS, FONT, LIBRARY_PLAY_BUTTON_SIZE, LIBRARY_PLAY_ICON_SIZE, SMALL_MARGIN, STANDARD_FONT_SIZE, STANDARD_MARGIN, TITLE_FONT } from '../../constants';
import { setIsProcessing } from '../../redux/recordingsSlice';
import { navigate } from '../../util/navigate';
import { selectRecordingById } from '../../recordings/recordingEditor';
import { formatDate, formatSeconds } from '../../util/stringFormatter';
import LibraryRecordingVisual from './LibraryRecordingVisual';

const Container = styled.TouchableOpacity`
  width: 100%;
`;

const Row = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const TextContainer = styled.View`
  flex: 1;
  align-items: flex-start;
`;

const Title = styled.Text`
  color: ${COLORS.DARK};
  font-size: ${STANDARD_FONT_SIZE}px;
  font-family: ${TITLE_FONT};
  line-height: ${STANDARD_FONT_SIZE}px;
  margin-bottom: ${SMALL_MARGIN}px;
`;

const Details = styled.Text`
  color: ${COLORS.DARK};
  font-size: ${STANDARD_FONT_SIZE}px;
  font-family: ${FONT};
  line-height: ${STANDARD_FONT_SIZE}px;
`;

const PlayButton = styled.TouchableOpacity`
  width: ${LIBRARY_PLAY_BUTTON_SIZE}px;
  height: ${LIBRARY_PLAY_BUTTON_SIZE}px;
  border-radius: ${LIBRARY_PLAY_BUTTON_SIZE / 2}px;
  background-color: ${props => props.isPlaying ? COLORS.DARK : COLORS.LIGHT};
  align-items: center;
  justify-content: center;
`;

const LibraryRecording = ({ recording }) => {

  const {
    storageId,
    name,
    createdEpoch,
    inputAudio: {
      rate,
      channels,
      data,
      duration,
    },
    beatbotData: {
      volume,
      beatStarts,
      beatEnds,
      classifications,
    }
  } = recording;

  const sound = useMemo(() => {
    return makeSound(data, rate, channels);
  }, [recording]);

  const dispatch = useDispatch();

  const [isPlaying, setIsPlaying] = useState(false);
  const [playTimeout, setPlayTimeout] = useState(null);

  const onPressContainer = () => {
    dispatch(setIsProcessing(true));
    navigate('Editor');
    selectRecordingById(storageId);
    dispatch(setIsProcessing(false));
  };

  const onPressPlay = () => {
    if (isPlaying) {
      sound.load();
      clearTimeout(playTimeout);
      setIsPlaying(false);
    } else {
      sound.play();
      const timeout = setTimeout(() => setIsPlaying(false), duration * 1000);
      setPlayTimeout(timeout);
      setIsPlaying(true);
    }
  };

  return (
    <Container onPress={onPressContainer}>
      <Row>
        <TextContainer>
          <Title>
            {name}
          </Title>
          <Details>
            {formatSeconds(Math.round(duration))}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {formatDate(createdEpoch)}
          </Details>
        </TextContainer>
        <PlayButton 
          onPress={onPressPlay}
          isPlaying={isPlaying}  
        >
          <FontAwesome5
            color={isPlaying ? COLORS.BACKGROUND : COLORS.DARK}
            size={LIBRARY_PLAY_ICON_SIZE}
            name={isPlaying ? 'stop' : 'play'}
            solid
          />
        </PlayButton>
      </Row>
      <Row>
        <LibraryRecordingVisual
          beatStarts={beatStarts}
          beatEnds={beatEnds}
          classifications={classifications}
          volumeLength={volume.length}
          duration={duration}
          isPlaying={isPlaying}
        />
      </Row>
    </Container>
  );
};

export default LibraryRecording;