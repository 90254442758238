import LZString from 'lz-string';

export const mergeRecordingUpdate = (recording, update) => {
  return {
    ...recording,
    ...update,
    beatbotData: {
      ...recording.beatbotData,
      ...update.beatbotData,
    },
  };
};

export const compressAudio = (data) => {
  const array = Array.from(data);
  const string = JSON.stringify(array);
  const compressed = LZString.compressToBase64(string);
  return compressed;
};

export const decompressAudio = (compressed) => {
  const string = LZString.decompressFromBase64(compressed);
  return JSON.parse(string);
};