import { Provider } from 'react-redux';
import LoadingScreen from './src/components/LoadingScreen';
import ModalConfirm from './src/components/ModalConfirm';
import ModalFeedback from './src/components/ModalFeedback';
import ModalMessage from './src/components/ModalMessage';
import ModalPrompt from './src/components/ModalPrompt';
import PreLoader from './src/features/PreLoader/PreLoader';
import { initStore } from './src/redux/initStore';
import { getStore } from './src/redux/store';

initStore();

const App = () => {
  return (
    <Provider store={getStore()}>
      <PreLoader />
      <ModalConfirm />
      <ModalPrompt />
      <ModalFeedback />
      <ModalMessage />
      <LoadingScreen />
    </Provider>
  );
};

export default App;

