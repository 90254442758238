import { Modal as RNModal } from 'react-native';
import styled from 'styled-components/native';
import { COLORS, MODAL_BORDER_RADIUS, STANDARD_MARGIN } from '../constants';

const Background = styled.TouchableOpacity`
  width: 100%;
  height: 100%;
  padding: ${props => props.padding ?? STANDARD_MARGIN}px;
  background-color: ${COLORS.DARK}88;
  align-items: center;
  justify-content: center;
`;

const Container = styled.TouchableOpacity`
  width: 100%;
  max-width: 500px;
  min-height: 50px;
  border-radius: ${MODAL_BORDER_RADIUS}px;
  background: ${COLORS.BACKGROUND};
`;

const Modal = ({ style, isVisible, close, children, padding }) => {
  return (
    <RNModal
      visible={isVisible}
      transparent={true}
      animationType='fade'
    >
      <Background padding={padding} activeOpacity={1} onPress={close}>
        <Container activeOpacity={1} style={style}>
          {children}
        </Container>
      </Background>
    </RNModal>
  );
};

export default Modal;