import { DRUM_SOUND_CHANNELS, DRUM_SOUND_RATE } from '../constants';
import * as wasm from './wasmApi';
import _ from 'lodash';
import { getDrumById } from '../audio/drumSounds/drumSounds';

// TODO: make this beatbot API durable enough to survive
//       being given invalid numPeaks or numClusters values

export const init = (sampleRate, numChannels) => {
  wasm.init(sampleRate, numChannels);
};

export const record = (buffer) => {
  const pointer = wasm.bufferToPointer(buffer);
  wasm.recordAudioSegment(pointer.address, buffer.byteLength / 2);
  pointer.free();
};

export const doneRecording = () => {
  wasm.doneRecording();
};

export const setNumPeaks = (numPeaks) => {
  wasm.setNumPeaks(numPeaks);
};

export const setNumClusters = (numClusters) => {
  wasm.setNumClusters(numClusters);
};

export const getBeatbotData = (
  drumMap,
  chosenNumPeaks = null, 
  chosenNumClusters = null,
) => {
  const volume = [];
  for (let i = 0; i < wasm.getAudioDataSize(); i++) {
    volume.push(wasm.getMelDbSum(i));
  }

  const numPeaksSize = wasm.getNumPeaksSize();
  const likelyNumPeaks = Array.from(wasm.int32PointerToArray(wasm.getLikelyNumPeaks(), numPeaksSize));
  const numPeaksLikelihoods = getConfidences(wasm.doublePointerToArray(wasm.getNumPeaksLikelihoods(), numPeaksSize));
  if (!chosenNumPeaks) {
    chosenNumPeaks = likelyNumPeaks[0];
  }

  const beatStarts = Array.from(wasm.int32PointerToArray(wasm.getBeatStarts(), chosenNumPeaks));
  const beatEnds = Array.from(wasm.int32PointerToArray(wasm.getBeatEnds(), chosenNumPeaks));

  const numClustersSize = wasm.getNumClustersSize();
  const likelyNumClusters = Array.from(wasm.int32PointerToArray(wasm.getLikelyNumClusters(), numClustersSize));
  const numClustersLikelihoods = getConfidences(wasm.doublePointerToArray(wasm.getNumClustersLikelihoods(), numClustersSize));
  if (!chosenNumClusters) {
    chosenNumClusters = likelyNumClusters[0];
  }

  const classifications = Array.from(wasm.int32PointerToArray(wasm.getClassifications(), chosenNumPeaks));

  const outputAudio = getOutputAudio(classifications, drumMap);

  return {
    volume,
    likelyNumPeaks,
    numPeaksLikelihoods,
    chosenNumPeaks,
    beatStarts,
    beatEnds,
    likelyNumClusters,
    numClustersLikelihoods,
    chosenNumClusters,
    classifications,
    outputAudio,
  };
};

const getConfidences = (likelihoods) => {
  let likelihoodSum = 0;
  for (let i = 0; i < likelihoods.length; i++) {
    likelihoodSum += likelihoods[i];
  }
  const output = [];
  for (let i = 0; i < likelihoods.length; i++) {
    output.push(Math.round(likelihoods[i] / likelihoodSum * 100));
  }
  return output;
};

const getOutputAudio = (classifications, drumMap) => {
  const uniqueClassifications = _.uniq(classifications);
  const drumIds = uniqueClassifications.map((_, index) => {
    const drumIndex = index % drumMap.length;
    return drumMap[drumIndex];
  });
  const drumDatas = drumIds.map((id) => getDrumById(id).data);
  const drumDataSizes = new Int32Array(drumDatas.map((data) => data.length));
  const drumDataBuffers = drumDatas.map((data) => data.buffer);
  const sizesPointer = wasm.bufferToPointer(drumDataSizes.buffer);
  const buffersPointer = wasm.buffersToPointerToPointers(drumDataBuffers);
  wasm.buildOutput(
    buffersPointer.address,
    sizesPointer.address,
    uniqueClassifications.length,
    DRUM_SOUND_RATE,
  );
  buffersPointer.free();
  sizesPointer.free();
  const outputDataLength = wasm.getOutputTrackLength();
  const outputData = Array.from(wasm.int16PointerToArray(wasm.getOutputTrack(), outputDataLength));
  return {
    rate: DRUM_SOUND_RATE,
    channels: DRUM_SOUND_CHANNELS,
    data: outputData,
    duration: outputData.length / DRUM_SOUND_RATE,
  };
};