import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  confirm: {
    isVisible: false,
    text: '',
    confirmText: '',
    onConfirm: () => null,
    onCancel: () => null,
  },
  prompt: {
    isVisible: false,
    initialValue: '',
    placeholder: '',
    confirmText: '',
    onConfirm: () => null,
    onCancel: () => null,
  },
  feedback: {
    isVisible: false,
  },
  message: {
    text: '',
    isVisible: false,
  },
};

export const modalsSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    showConfirmModal: (state, action) => {
      state.confirm.isVisible = true;
      state.confirm.text = action.payload.text;
      state.confirm.confirmText = action.payload.confirmText;
      state.confirm.onConfirm = action.payload.onConfirm;
      state.confirm.onCancel = action.payload.onCancel || (() => null);
    },
    hideConfirmModal: (state) => {
      state.confirm.isVisible = false;
    },
    showPromptModal: (state, action) => {
      state.prompt.isVisible = true;
      state.prompt.initialValue = action.payload.initialValue || '';
      state.prompt.placeholder = action.payload.placeholder;
      state.prompt.confirmText = action.payload.confirmText;
      state.prompt.onConfirm = action.payload.onConfirm;
      state.prompt.onCancel = action.payload.onCancel || (() => null);
    },
    hidePromptModal: (state) => {
      state.prompt.isVisible = false;
    },
    showFeedbackModal: (state) => {
      state.feedback.isVisible = true;
    },
    hideFeedbackModal: (state) => {
      state.feedback.isVisible = false;
    },
    showMessageModal: (state, action) => {
      state.message.isVisible = true;
      state.message.text = action.payload;
    },
    hideMessageModal: (state) => {
      state.message.isVisible = false;
    },
  },
});

export const { 
  showConfirmModal,
  hideConfirmModal,
  showPromptModal,
  hidePromptModal,
  showFeedbackModal,
  hideFeedbackModal,
  showMessageModal,
  hideMessageModal,
} = modalsSlice.actions;

export default modalsSlice.reducer;