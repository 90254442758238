import { FontAwesome5 } from '@expo/vector-icons';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/native';
import { drumSounds } from '../../audio/drumSounds/drumSounds';
import { makeSound } from '../../audio/player/player.web';
import { PrimaryButton } from '../../components/Button';
import Modal from '../../components/Modal';
import { BEATBOT_OSAMP, BEATBOT_WINDOW_SIZE, BUTTON_BORDER_WIDTH, COLORS, FONT_BOLD, SMALL_MARGIN, STANDARD_FONT_SIZE, STANDARD_MARGIN, TITLE_FONT, TITLE_FONT_SIZE } from '../../constants';
import { setSelectedDrumMap } from '../../recordings/recordingEditor';
import { framesToSamples } from '../../util/conversion';
import DrumPickerModalFolder from './DrumPickerModalFolder';

const StyledModal = styled(Modal)`
  flex: 1;
  padding: ${STANDARD_MARGIN}px;
`;

const Header = styled.View`
  margin-bottom: ${SMALL_MARGIN}px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.Text`
  font-family: ${TITLE_FONT};
  font-size: ${TITLE_FONT_SIZE}px;
  color: ${COLORS.DARK};
`;

const SoundButton = styled.TouchableOpacity`
  height: 30px;
  border-radius: 15px;
  border: ${BUTTON_BORDER_WIDTH}px solid ${COLORS.DARK};
  flex-direction: row;
  justify-content: space-around; 
  align-items: center;
  padding-horizontal: ${SMALL_MARGIN}px;
`;

const SoundButtonText = styled.Text`
  font-family: ${FONT_BOLD};
  font-size: ${STANDARD_FONT_SIZE}px;
  line-height: ${STANDARD_FONT_SIZE}px;
  margin-top: 3px;
  color: ${COLORS.DARK};
  margin-left: ${SMALL_MARGIN}px;
`;

const Folders = styled.ScrollView`
`;

const Buttons = styled.View`
  flex-direction: row;
  margin-top: ${SMALL_MARGIN}px;
`;

const DoneButton = styled(PrimaryButton)`
  flex: 1;
`;

const DrumPickerModal = ({
  isVisible,
  setIsVisible,
  classification,
  drumMap,
  classifications,
  beatStarts,
  beatEnds,
  inputAudio,
}) => {

  const colorIndex = classification % COLORS.HIGHLIGHTS.length;
  const color = COLORS.HIGHLIGHTS[colorIndex];

  const drumIndex = classification % drumMap.length;
  const selectedDrumId = drumMap[drumIndex];
  const setSelectedDrumId = (newDrumId) => {
    const newDrumMap = [...drumMap];
    newDrumMap[drumIndex] = newDrumId;
    setSelectedDrumMap(newDrumMap);
  };

  let beatIndex = 0;
  for (let i = 0; i < classifications.length; i++) {
    if (classifications[i] === classification) {
      beatIndex = i;
    }
  } 
  const soundStart = framesToSamples(beatStarts[beatIndex], BEATBOT_WINDOW_SIZE, BEATBOT_OSAMP);
  const soundEnd = framesToSamples(beatEnds[beatIndex], BEATBOT_WINDOW_SIZE, BEATBOT_OSAMP);
  const audio = inputAudio.data.slice(soundStart, soundEnd);
  const sound = makeSound(new Int16Array(audio), inputAudio.rate, inputAudio.channels);

  const onPressInput = () => {
    sound.play();
  };

  const onPressDone = () => {
    setIsVisible(false);
  };

  return (
    <StyledModal
      isVisible={isVisible}
      close={() => setIsVisible(false)}
    >
      <Header>
        <Title>
          Instrument
        </Title>
        <SoundButton onPress={onPressInput}>
          <FontAwesome5 
            size={14}
            name='volume-up'
            color={COLORS.DARK}
            solid
          />
          <SoundButtonText>input</SoundButtonText>
        </SoundButton>
      </Header>
      <Folders contentContainerStyle={{ flex: 1 }}>
        {drumSounds.map((drumSoundFolder, index) => (
          <DrumPickerModalFolder
            key={`drum-sound-folder-${index}`}
            drumSoundFolder={drumSoundFolder}
            color={color}
            selectedDrumId={selectedDrumId}
            setSelectedDrumId={setSelectedDrumId}
          />
        ))}
      </Folders>
      <Buttons>
        <DoneButton
          text='Done'
          onPress={onPressDone}
        />
      </Buttons>
    </StyledModal>  
  );
};

export default DrumPickerModal;