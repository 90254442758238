import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';
import { WEB_RECORDER_SKIP_MS, WEB_RECORDER_TIME_SLICE_MS } from '../../constants';

let mediaRecorder;
let recordingSettings;
let numSegmentsRecorded;
let recordingPromise;
let recordedAudio;
let isInitialized = false;
let isWavRegistered = false;

const callbacks = {};

export const hasPermissions = async () => {
  try {
    await navigator.mediaDevices.getUserMedia({ audio: true });
    return true;
  } catch (e) {
    isInitialized = false;
    return false;
  }
};

export const getIsInitialized = () => isInitialized;

export const init = async () => {
  if (!isWavRegistered) {
    await register(await connect());
    isWavRegistered = true;
  }
  try {
    console.log(1);
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    console.log(2);
    recordingSettings = stream.getAudioTracks()[0].getSettings();
    console.log(3);
    mediaRecorder = new MediaRecorder(stream, { mimeType: 'audio/wav' });
    console.log(4);
    mediaRecorder.ondataavailable = onMediaRecorderDataAvailable;
    console.log(5);
    mediaRecorder.onstop = onMediaRecorderStop;
    console.log(5);
    isInitialized = true;
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const setOnDataAvailable = (onDataAvailable) => callbacks.onDataAvailable = onDataAvailable;

export const setOnStop = (onStop) => callbacks.onStop = onStop;

export const record = () => {
  numSegmentsRecorded = 0;
  recordedAudio = new Uint8Array();
  recordingPromise = null;
  mediaRecorder.start(WEB_RECORDER_TIME_SLICE_MS);
};

export const stop = () => {
  mediaRecorder.stop();
};

export const getRecordedAudio = () => {
  return new Int16Array(recordedAudio.buffer);
};

export const getSampleRate = () => {
  if (recordingSettings) {
    return recordingSettings.sampleRate || 44100;
  }
  return null;
};

export const getNumChannels = () => {
  if (recordingSettings) {
    return recordingSettings.channelCount || 1;
  }
  return null;
};

export const getDuration = () => {
  return getRecordedAudio().length / getSampleRate();
};

const onMediaRecorderDataAvailable = (event) => {
  const promise = onDataAvailablePromise(event);
  if (recordingPromise) {
    recordingPromise = Promise.all([recordingPromise, promise]);
  } else {
    recordingPromise = promise;
  }
};

const onDataAvailablePromise = async (event) => {
  const buffer = await event.data.arrayBuffer();
  saveRecordedAudio(buffer);
  numSegmentsRecorded++;
  const msRecorded = numSegmentsRecorded * WEB_RECORDER_TIME_SLICE_MS;
  if (msRecorded >= WEB_RECORDER_SKIP_MS) {
    callbacks.onDataAvailable(buffer);
  }
};

const saveRecordedAudio = (arrayBuffer) => {
  const incomingAudio = new Uint8Array(arrayBuffer);
  const combinedAudio = new Uint8Array(recordedAudio.length + incomingAudio.length);
  combinedAudio.set(recordedAudio);
  combinedAudio.set(incomingAudio, recordedAudio.length);
  recordedAudio = combinedAudio;
};

const onMediaRecorderStop = async () => {
  await recordingPromise;
  callbacks.onStop();
};
