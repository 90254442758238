import { useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { COLORS } from '../../constants';
import SettingsSignedIn from './SettingsSignedIn';
import SettingsSignedOut from './SettingsSignedOut';

const Container = styled.View`
  background-color: ${COLORS.BACKGROUND};
  flex: 1;
`;

const Settings = () => {
  const isSignedIn = useSelector(state => state.user.isSignedIn);
  const content = isSignedIn ? <SettingsSignedIn /> : <SettingsSignedOut />;
  return (
    <Container>
      {content}
    </Container>
  );
};

export default Settings;