import { createAsyncThunk } from '@reduxjs/toolkit';
import _ from 'lodash';
import { DEVICE_INFO, RECORDING_ACTION_TYPE, RECORDING_OMITTED_FIELDS } from '../../constants';
import { createRecordingLocally } from '../../recordings/recordingStorage';
import { updateRecordingLocally } from '../../recordings/recordingStorage';
import { compressAudio, decompressAudio } from '../../recordings/recordingUtil';
import { getState } from '../store';
import { apiGet, apiPost, apiPut } from './api';

export const getRecordingFromCloud = createAsyncThunk(
  RECORDING_ACTION_TYPE + 'getRecordingFromCloud',
  async (recordingId) => {
    try {
      const { userId } = getState().user;
      const { recording } = await apiGet(`/users/${userId}/recordings/${recordingId}`);
      recording.isBackedUp = true;
      recording.isDeleted = false;
      recording.inputAudio.data = decompressAudio(recording.inputAudio.data);
      await createRecordingLocally(recording);
      return recording;
    }
    catch (e) {
      return Promise.reject(e);
    }
  }
);

export const createRecordingOnCloud = createAsyncThunk(
  RECORDING_ACTION_TYPE + 'createRecordingOnCloud',
  async (recording) => {
    try {
      const recordingDTO = _.omit(recording, RECORDING_OMITTED_FIELDS);
      recordingDTO.deviceInfo = DEVICE_INFO;
      recordingDTO.inputAudio.data = compressAudio(recordingDTO.inputAudio.data);
      const { recordingId } = await apiPost('/recordings', { recordingDTO });
      await updateRecordingLocally({
        ...recording,
        _id: recordingId,
        isBackedUp: true,
      });
      return { 
        storageId: recording.storageId,
        _id: recordingId
      };
    }
    catch (e) {
      return Promise.reject(e);
    }
  }
);

export const updateRecordingOnCloud = createAsyncThunk(
  RECORDING_ACTION_TYPE + 'updateRecordingOnCloud',
  async (recording) => {
    try {
      if (!recording._id) {
        console.log('Not updated on cloud.');
        return Promise.reject('Cannot update: missing _id');
      }
      const recordingDTO = _.omit(recording, ['inputAudio', 'userId', ...RECORDING_OMITTED_FIELDS]);
      await apiPut(`/recordings/${recording._id}`, { recordingDTO });
      await updateRecordingLocally({
        ...recording,
        isBackedUp: true,
      });
      return { storageId: recording.storageId };
    }
    catch (e) {
      return Promise.reject(e);
    }
  }
);

export const deleteRecordingOnCloud = createAsyncThunk(
  RECORDING_ACTION_TYPE + 'deleteRecordingOnCloud',
  async (recording) => {
    try {
      if (!recording._id) {
        console.log('Not deleted on cloud.');
        return Promise.reject('Cannot delete: missing recording _id');
      }
      await apiPost(`/recordings/${recording._id}/delete`, {});
      await updateRecordingLocally({
        ...recording,
        isBackedUp: true,
      });
      return { storageId: recording.storageId };
    }
    catch (e) {
      return Promise.reject(e);
    }
  }
);