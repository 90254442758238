import config from '../../config';
import { getState } from '../store';

const request = (route, options) => {
  return new Promise(async (resolve, reject) => {
    const { authToken, deviceId } = getState().user;
    
    options = options || {};
    options.headers = options.headers || {};
    if (authToken) {
      options.headers.Authorization = `Bearer ${authToken}`;
    }

    if (options.body) {
      options.headers['Content-Type'] = 'application/json';
      options.body.deviceId = deviceId;
      options.body = JSON.stringify(options.body);
    }

    options.headers.Accept = 'application/json';
    
    try {
      const res = await fetch(config.API_BASE + route, options);
      if (res.ok) {
        let data;
        try { 
          data = await res.json(); 
        } catch (e) {}
        resolve(data);
      } else {
        reject(res.status);
      }
    }
    catch (e) {
      reject(e);
    }
  });
};

export const apiGet = (route) => {
  return request(route);
};

export const apiPost = (route, body) => {
  return request(route, { method: 'POST', body });
};

export const apiPut = (route, body) => {
  return request(route, { method: 'PUT', body });
};

export const apiDelete = (route) => {
  return request(route, { method: 'DELETE' });
};