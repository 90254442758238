import BackButton from '../../components/BackButton'
import { saveSelected } from '../../recordings/recordingEditor';

const EditorBackButton = () => {
  return (
    <BackButton callback={saveSelected} />
  );
}

export default EditorBackButton;
