import OverflowMenu from '../../components/OverflowMenu';
import { useDispatch, useSelector } from 'react-redux';
import { selectFocusedRecording } from '../../redux/selectors';
import { showConfirmModal, showFeedbackModal, showPromptModal } from '../../redux/modalsSlice';
import { navigateBack } from '../../util/navigate';
import { DEFAULT_RECORDING_NAME } from '../../constants';
import { deleteSelected, renameSelected } from '../../recordings/recordingEditor';

const EditorOverflowMenu = () => {
  
  const dispatch = useDispatch();
  
  const recordingName = useSelector((state) => selectFocusedRecording(state)?.name);

  return (
    <OverflowMenu 
      options={[
        {
          text: 'Rename',
          onPress: () => {
            dispatch(showPromptModal({
              initialValue: recordingName,
              placeholder: DEFAULT_RECORDING_NAME,
              confirmText: 'Rename',
              onConfirm: (value) => {
                renameSelected(value || DEFAULT_RECORDING_NAME);
              }
            }));
          },
        },
        {
          text: 'Delete',
          onPress: () => {
            dispatch(showConfirmModal({
              text: `Delete \'${recordingName}\'?`,
              confirmText: 'Delete',
              onConfirm: () => {
                navigateBack();
                deleteSelected();
              }
            }));
          },
        },
        {
          text: 'Send feedback',
          onPress: () => {
            dispatch(showFeedbackModal());
          },
        },
      ]}
    />
  );
};

export default EditorOverflowMenu;