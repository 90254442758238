import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { USER_STOARGE_KEY } from '../constants';
import * as storage from '../util/storage';
import { logIn } from './apiActions/authTokenActions';
import { signUp } from './apiActions/userActions';
import { v4 as uuidv4 } from 'uuid';

const initialState = {
  isStateLoaded: false,
  isSignedIn: false,
  accountEmail: null,
  authToken: null,
  userId: null,
  deviceId: uuidv4(),
};

const saveState = (state) => {
  storage.save(USER_STOARGE_KEY, {
    isSignedIn: state.isSignedIn,
    accountEmail: state.accountEmail,
    authToken: state.authToken,
    userId: state.userId,
    deviceId: state.deviceId,
  });
};

export const loadState = createAsyncThunk(
  'user/loadState',
  async () => {
    const storageData = await storage.load(USER_STOARGE_KEY);
    if (storageData) {
      return storageData;
    } else {
      saveState(initialState);
      return initialState;
    }
  }
);

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logOut: (state) => {
      state.isSignedIn = false;
      state.accountEmail = null;
      state.authToken = null;
      state.userId = null;
      saveState(state);
    },
  },
  extraReducers: {
    [loadState.fulfilled]: (state, action) => {
      state.isSignedIn = action.payload.isSignedIn;
      state.accountEmail = action.payload.accountEmail;
      state.isStateLoaded = true;
      state.authToken = action.payload.authToken;
      state.userId = action.payload.userId;
      state.deviceId = action.payload.deviceId;
    },
    [signUp.fulfilled]: (state, action) => {
      state.isSignedIn = true;
      state.accountEmail = action.payload.user.email;
      state.authToken = action.payload.authToken;
      state.userId = action.payload.user._id;
      saveState(state);
    },
    [logIn.fulfilled]: (state, action) => {
      state.isSignedIn = true;
      state.accountEmail = action.payload.user.email;
      state.authToken = action.payload.authToken;
      state.userId = action.payload.user._id;
      saveState(state);
    },
  }
});

export const { logOut } = userSlice.actions;

export default userSlice.reducer;
