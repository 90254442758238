import _ from 'lodash';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { COLORS, NAV_BAR_HEIGHT, STANDARD_MARGIN } from '../../constants';
import LibraryEmpty from './LibraryEmpty';
import LibraryRecording from './LibraryRecording';

const Container = styled.View`
  flex: 1;
  background-color: ${COLORS.BACKGROUND}; 
  align-items: center;
`;

const LibraryList = styled.FlatList`
  width: 100%;
  max-width: 900px;
  padding-horizontal: ${STANDARD_MARGIN}px;
`;

const LibraryRecordingContainer = styled.View`
  width: 100%;
`;

const Divider = styled.View`
  width: 100%;
  height: 2px;
  border-radius: 1px;
  background-color: ${COLORS.LIGHT};
  margin-vertical: ${STANDARD_MARGIN}px;
`;

const Spacer = styled.View`
  width: 100%;
  height: ${STANDARD_MARGIN * 2 + NAV_BAR_HEIGHT}px;
`;

const Library = () => {

  let recordings = useSelector(state => state.recordings.recordings);
  recordings = _(recordings)
    .filter(recording => !recording.isDeleted)
    .sortBy((recording) => -recording.createdEpoch)
    .value();

  return (
    <Container>
      {recordings.length === 0 ? <LibraryEmpty /> : (
        <LibraryList
          data={recordings}
          keyExtractor={(recording, index) => `${index}${recording.storageId}`}
          renderItem={(recording) => (
            <>
              <LibraryRecordingContainer>
                <LibraryRecording recording={recording.item} />
              </LibraryRecordingContainer>
              {recording.index === recordings.length - 1 ? <Spacer /> : <Divider />}
            </>
          )}
        />
      )}
    </Container>
  );
};

export default Library;
