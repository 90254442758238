import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { makeSound } from '../../audio/player/player.web';
import { PrimaryButton, SecondaryButton } from '../../components/Button';
import { EDITOR_MAX_NUM_CLUSTERS, EDITOR_MAX_NUM_PEAKS, SMALL_MARGIN, STANDARD_MARGIN } from '../../constants';
import { selectFocusedRecording } from '../../redux/selectors';
import { setSelectedNumClusters, setSelectedNumPeaks } from '../../recordings/recordingEditor';
import AudioVisual from './AudioVisual';
import ConfidencePicker from './ConfidencePicker';
import DrumPicker from './DrumPicker';
import DrumPickerModal from './DrumPickerModal';
import EditorBackButton from './EditorBackButton';
import ExportModal from './ExportModal';

const Container = styled.View`
  flex: 1;
  align-items: center;
`;

const TopSection = styled.View`
  align-items: center;
  flex: 1;
  width: 100%;
`;

const BottomSection = styled.View`
  align-items: center;
  width: 100%;
`;

const ConfidencePickerContainer = styled.View`
  margin-top: ${STANDARD_MARGIN}px;
  width: 100%;
  max-width: 1000px;
`;

const Buttons = styled.View`
  align-items: center;
  width: 100%;
  max-width: 500px;
  padding: ${STANDARD_MARGIN}px;
`;

const ButtonsTop = styled.View`
  flex-direction: row;
  justify-content: center;
  margin-bottom: ${SMALL_MARGIN}px;
  margin-horizontal: ${STANDARD_MARGIN}px;
  width: 100%;
`;

const PlayInputButton = styled(SecondaryButton)`
  margin-right: ${SMALL_MARGIN / 2}px;
  flex: 1;
`;

const PlayButton = styled(PrimaryButton)`
  margin-left: ${SMALL_MARGIN / 2}px;
  flex: 1;
`;

let inputSound;
let outputSound;

const EditorLoaded = ({ navigation }) => {
  
  const recording = useSelector(state => selectFocusedRecording(state));

  useEffect(() => {
    navigation.setOptions({ 
      title: recording.name,
      headerLeft: EditorBackButton,
    });
  }, [recording]);

  const inputAudio = useSelector(state => selectFocusedRecording(state).inputAudio);
  const outputAudio = useSelector(state => selectFocusedRecording(state).beatbotData.outputAudio);

  useEffect(() => {
    if (inputSound) {
      inputSound.load();
    }
    if (inputAudio) {
      const { data, rate, channels } = inputAudio;
      inputSound = makeSound(data, rate, channels);
    }
  }, [inputAudio]);

  useEffect(() => {
    if (outputSound) {
      outputSound.load();
    }
    if (outputAudio) {
      const { data, rate, channels } = outputAudio;
      outputSound = makeSound(data, rate, channels);
    }
  }, [outputAudio]);

  const [isDrumPickerModalVisible, setIsDrumPickerModalVisible] = useState(false);
  const [selectedClassification, setSelectedClassification] = useState(-1);

  const [isInputPlaying, setIsInputPlaying] = useState(false);
  const [isOutputPlaying, setIsOutputPlaying] = useState(false);
  const [playInputTimeout, setPlayInputTimeout] = useState(null);
  const [playOutputTimeout, setPlayOutputTimeout] = useState(null);

  const playInput = () => {
    inputSound.onplay = () => {
      const timeout = setTimeout(() => setIsInputPlaying(false), recording.inputAudio.duration * 1000);
      setPlayInputTimeout(timeout);
      setIsInputPlaying(true);
    };
    inputSound.play();
  };

  const playOutput = () => {
    outputSound.onplay = () => {
      const timeout = setTimeout(() => setIsOutputPlaying(false), recording.inputAudio.duration * 1000); // Yes, this should be inputDuration
      setPlayOutputTimeout(timeout);
      setIsOutputPlaying(true);
    };
    outputSound.play();
  };

  const stopInput = () => {
    inputSound.load();
    clearTimeout(playInputTimeout);
    setIsInputPlaying(false);
  };

  const stopOutput = () => {
    outputSound.load();
    clearTimeout(playOutputTimeout);
    setIsOutputPlaying(false);
  };

  const onPressPlayInput = () => {
    if (isInputPlaying) {
      stopInput();
    } else {
      stopOutput();
      playInput();
    }
  };

  const onPressPlayOutput = () => {
    if (isOutputPlaying) {
      stopOutput();
    } else {
      stopInput();
      playOutput();
    }
  };

  const [isExportModalVisible, setIsExportModalVisible] = useState(false);

  const onPressExport = () => {
    setIsExportModalVisible(true);
  };

  useEffect(() => {
    return () => {
      stopInput();
      stopOutput();
    };
  }, []);

  return (
    <Container>
      <TopSection>
        <AudioVisual
          volume={recording.beatbotData.volume}
          inputDuration={recording.inputAudio.duration}
          beatStarts={recording.beatbotData.beatStarts}
          beatEnds={recording.beatbotData.beatEnds}
          classifications={recording.beatbotData.classifications}
          isInputPlaying={isInputPlaying}
          isOutputPlaying={isOutputPlaying}
        />
        <DrumPicker
          classifications={recording.beatbotData.classifications}
          drumMap={recording.drumMap}
          showModal={() => setIsDrumPickerModalVisible(true)}
          setSelectedClassification={setSelectedClassification}
        />
        <ConfidencePickerContainer>
          <ConfidencePicker
            label='total beats'
            numbers={recording.beatbotData.likelyNumPeaks.slice(0, EDITOR_MAX_NUM_PEAKS)}
            confidences={recording.beatbotData.numPeaksLikelihoods.slice(0, EDITOR_MAX_NUM_PEAKS)}
            selectNumber={setSelectedNumPeaks}
            selectedNumber={recording.beatbotData.chosenNumPeaks}
          />
        </ConfidencePickerContainer>
        <ConfidencePickerContainer>
          <ConfidencePicker
            label='unique sounds'
            numbers={recording.beatbotData.likelyNumClusters.slice(0, EDITOR_MAX_NUM_CLUSTERS)}
            confidences={recording.beatbotData.numClustersLikelihoods.slice(0, EDITOR_MAX_NUM_CLUSTERS)}
            selectNumber={setSelectedNumClusters}
            selectedNumber={recording.beatbotData.chosenNumClusters}
          />
        </ConfidencePickerContainer>
      </TopSection>
      <BottomSection>
        <Buttons>
          <ButtonsTop>
            <PlayInputButton
              onPress={onPressPlayInput}
              text={isInputPlaying ? 'Stop input' : 'Play input'}
              icon={isInputPlaying ? 'stop' : 'play'}
            />
            <PlayButton
              onPress={onPressPlayOutput}
              text={isOutputPlaying ? 'Stop': 'Play'}
              icon={isOutputPlaying ? 'stop' : 'play'}
            />
          </ButtonsTop>
          <SecondaryButton
            text='Export...'
            onPress={onPressExport}
          />
        </Buttons>
      </BottomSection>
      <DrumPickerModal
        isVisible={isDrumPickerModalVisible}
        setIsVisible={setIsDrumPickerModalVisible}
        classification={selectedClassification}
        classifications={recording.beatbotData.classifications}
        drumMap={recording.drumMap}
        beatStarts={recording.beatbotData.beatStarts}
        beatEnds={recording.beatbotData.beatEnds}
        inputAudio={recording.inputAudio}
      />
      <ExportModal
        isVisible={isExportModalVisible}
        setIsVisible={setIsExportModalVisible}
      />
    </Container>
  )
};

export default EditorLoaded;