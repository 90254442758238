import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { COLORS, FONT_BOLD, SMALL_MARGIN, STANDARD_MARGIN, TITLE_FONT_SIZE } from '../constants';
import { hideMessageModal } from '../redux/modalsSlice';
import { PrimaryButton } from './Button';
import Modal from './Modal';

const Container = styled.View`
  padding: ${STANDARD_MARGIN}px;
`;

const Text = styled.Text`
  font-family: ${FONT_BOLD};
  font-size: ${TITLE_FONT_SIZE}px;
  line-height: ${TITLE_FONT_SIZE * 1.5}px;
  margin-bottom: ${SMALL_MARGIN}px;
  color: ${COLORS.DARK};
`;

const OkayButton = styled(PrimaryButton)`
`;

const ModalMessage = () => {

  const {
    isVisible,
    text,
  } = useSelector(state => state.modals.message);

  const dispatch = useDispatch()

  const onPressOkay = () => {
    dispatch(hideMessageModal());
  };

  return (
    <Modal
      isVisible={isVisible}
      close={onPressOkay}
    >
      <Container>
        <Text>
          {text}
        </Text>
        <OkayButton
          text='Okay'
          onPress={onPressOkay}
        />
      </Container>
    </Modal>
  )
};

export default ModalMessage;
