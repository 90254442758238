import { FontAwesome5 } from '@expo/vector-icons';
import { useEffect, useMemo, useRef } from 'react';
import { Animated } from 'react-native';
import styled from 'styled-components/native';
import { ANIMATION_USE_NATIVE_DRIVER, COLORS, CONFIDENCE_PICKER_ARROW_SIZE, FONT, STANDARD_FONT_SIZE, STANDARD_MARGIN, TITLE_FONT, TITLE_FONT_SIZE } from '../../constants';

const Container = styled.View`
  width: 100%;
  overflow: hidden;
  position: relative;
`;

const SlidingContianer = styled.View`
  width: ${props => props.numbersLength * 100}%;
  flex-direction: row;
`;

const AnimatedSlidingContainer = Animated.createAnimatedComponent(SlidingContianer);

const Slide = styled.View`
  width: ${props => 100 / props.numbersLength}%;
`;

const SlideTitle = styled.Text`
  font-family: ${TITLE_FONT};
  font-size: ${TITLE_FONT_SIZE}px;
  line-height: ${TITLE_FONT_SIZE}px;
  color: ${COLORS.DARK};
  text-align: center;
`;

const SlideText = styled.Text`
  font-family: ${FONT};
  font-size: ${STANDARD_FONT_SIZE}px;
  color: ${COLORS.DARK};
  text-align: center;
`;

const LeftArrowButton = styled.TouchableOpacity`
  position: absolute;
  left: 0;
  top: 2px;
  padding-left: ${STANDARD_MARGIN}px;
`;

const RightArrowButton = styled.TouchableOpacity`
  position: absolute;
  right: 0;
  top: 2px;
  padding-right: ${STANDARD_MARGIN}px;
`;

let animatedMarginPercent = '0%';

const ConfidencePicker = ({
  label,
  numbers,
  confidences,
  selectNumber,
  selectedNumber,
  style
}) => {

  let selectedIndex = 0;
  for (let i = 0; i < numbers.length; i++) {
    if (numbers[i] === selectedNumber) {
      selectedIndex = i;
    }
  }

  const animatedMargin = useRef(new Animated.Value(0)).current;
  const animateToIndex = (index) => {
    Animated.timing(animatedMargin, {
      toValue: index,
      duration: 350,
      useNativeDriver: ANIMATION_USE_NATIVE_DRIVER
    }).start();
  };
  if (animatedMargin) {
    animatedMarginPercent = animatedMargin.interpolate({
      inputRange: [0, numbers.length - 1],
      outputRange: ['0%', `-${100 * (numbers.length - 1)}%`]
    });
  }
  
  useEffect(() => {
    animateToIndex(selectedIndex);
  }, [selectedNumber]);

  const onPressLeft = () => {
    selectNumber(numbers[selectedIndex - 1]);
  };

  const onPressRight = () => {
    selectNumber(numbers[selectedIndex + 1]);
  };

  return (
    <Container style={style}>
      <AnimatedSlidingContainer
        numbersLength={numbers.length}
        style={{ marginLeft: animatedMarginPercent }}
      >
        {numbers.map((number, index) => {
          return (
            <Slide
              key={`slide-${index}-${number}`}
              numbersLength={numbers.length}
            >
              <SlideTitle>
                {number} {label}
              </SlideTitle>
              <SlideText>
                {confidences[index]}% confidence
              </SlideText>
            </Slide>
          );
        })}
      </AnimatedSlidingContainer>

      {selectedIndex === 0 ? null : (
        <LeftArrowButton onPress={onPressLeft}>
          <FontAwesome5 name='chevron-circle-left' size={CONFIDENCE_PICKER_ARROW_SIZE} color={COLORS.DARK} solid />
        </LeftArrowButton>
      )}
      {selectedIndex === numbers.length - 1 ? null : (
        <RightArrowButton onPress={onPressRight}>
          <FontAwesome5 name='chevron-circle-right' size={CONFIDENCE_PICKER_ARROW_SIZE} color={COLORS.DARK} solid />
        </RightArrowButton>
      )}
    </Container>
  );
};

export default ConfidencePicker;