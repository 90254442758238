import { createAsyncThunk } from '@reduxjs/toolkit';
import { USER_ACTION_TYPE } from '../../constants';
import { apiPost } from './api';

export const signUp = createAsyncThunk(
  USER_ACTION_TYPE + 'signUp',
  ({ email, password }) => {
    try {
      return apiPost('/users', { userDTO: { email, password }});
    }
    catch (e) {
      return Promise.reject(e);
    }
  }
);

