import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { navigationRef } from '../../util/navigate';
import BackButton from '../../components/BackButton';
import { SCREEN_OPTIONS } from '../../constants';
import Editor from '../Editor/Editor';
import EditorOverflowMenu from '../Editor/EditorOverflowMenu';
import Library from '../Library/Library';
import Record from '../Record/Record';
import Settings from '../Settings/Settings';
import { LogIn, SignUp } from '../SignIn/SignIn';
import NavigationBar from './NavigationBar';
import FeedbackOverflowMenu from '../../components/FeedbackOverflowMenu';
import EditorBackButton from '../Editor/EditorBackButton';

const Stack = createNativeStackNavigator();
const Tab = createBottomTabNavigator();

const TabNavigator = () => {
  return (
    <Tab.Navigator tabBar={NavigationBar}>
      <Tab.Screen
        name='Record'
        component={Record}
        options={{
          ...SCREEN_OPTIONS,
          title: 'Beatbox',
          headerRight: FeedbackOverflowMenu,
        }}
      />
      <Tab.Screen
        name='Library'
        component={Library}
        options={{
          ...SCREEN_OPTIONS,
          headerRight: FeedbackOverflowMenu,
        }}
      />
      <Tab.Screen
        name='Settings'
        component={Settings}
        options={{
          ...SCREEN_OPTIONS,
        }}
      />
    </Tab.Navigator>
  );
};

const Navigator = () => {
  return (
    <NavigationContainer ref={navigationRef}>
      <Stack.Navigator>
        <Stack.Screen
          name='Tab Navigator'
          component={TabNavigator}
          options={{ headerShown: false }}
        />
        <Stack.Screen
          name='Sign up'
          component={SignUp}
          options={{
            ...SCREEN_OPTIONS,
            headerLeft: BackButton,
          }}
        />
        <Stack.Screen
          name='Log in'
          component={LogIn}
          options={{
            ...SCREEN_OPTIONS,
            headerLeft: BackButton,
          }}
        />
        <Stack.Screen
          name='Editor'
          component={Editor}
          options={{
            ...SCREEN_OPTIONS,
            headerLeft: EditorBackButton,
            headerRight: EditorOverflowMenu,
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default Navigator;