import { useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { COLORS } from '../../constants';
import EditorLoaded from './EditorLoaded';
import EditorLoading from './EditorLoading';

const Container = styled.View`
  flex: 1;
  background-color: ${COLORS.BACKGROUND};
`;

const Editor = ({ navigation }) => {
  const isLoading = useSelector(state => state.recordings.isProcessing);
  const content = isLoading ? <EditorLoading navigation={navigation} /> : <EditorLoaded navigation={navigation} />;
  return (
    <Container>
      {content}
    </Container>
  );
};

export default Editor;