import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { PrimaryButton, SecondaryButton } from '../../components/Button';
import { COLORS, FONT, LARGE_FONT_SIZE, NAV_BAR_HEIGHT, SMALL_MARGIN, STANDARD_MARGIN, TITLE_FONT } from '../../constants';
import { showFeedbackModal } from '../../redux/modalsSlice';
import { logOut } from '../../redux/userSlice';

const Container = styled.View`
  flex: 1;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: ${SMALL_MARGIN}px ${STANDARD_MARGIN}px ${STANDARD_MARGIN}px;
  margin-bottom: ${NAV_BAR_HEIGHT + STANDARD_MARGIN}px;
  position: relative;
`;

const TextContainer = styled.View`
  align-items: center;
  width: 100%;
  max-width: 900px;
`;

const Title = styled.Text`
  font-family: ${TITLE_FONT};
  color: ${COLORS.DARK};
  font-size: ${LARGE_FONT_SIZE}px;
  line-height: ${LARGE_FONT_SIZE}px;
  margin-bottom: ${SMALL_MARGIN}px;
  text-align: left;
  width: 100%;
`;

const Text = styled.Text`
  font-family: ${FONT};
  color: ${COLORS.DARK};
  font-size: ${LARGE_FONT_SIZE}px;
  line-height: ${LARGE_FONT_SIZE}px;
  margin-bottom: ${STANDARD_MARGIN}px;
  text-align: left;
  width: 100%;
`;

const ButtonContainer = styled.View`
  width: 100%;
  align-items: center;
`;

const FeedbackButton = styled(PrimaryButton)`
  margin-bottom: ${SMALL_MARGIN}px;
`;

const SettingsSignedIn = () => {
  
  const dispatch = useDispatch();

  const onPressLogOut = () => {
    dispatch(logOut());
  };

  const onPressFeedback = () => {
    dispatch(showFeedbackModal());
  };

  const email = useSelector(state => state.user.accountEmail);

  return (
    <Container>
      <TextContainer>
        <Title>Account</Title>
        <Text>{email}</Text>
        <Title>Cloud backups</Title>
        <Text>enabled</Text>
        <Title>Pro mode</Title>
        <Text>activated (early adopter)</Text>
      </TextContainer>
      <ButtonContainer>
        <FeedbackButton 
          text='Send feedback'
          onPress={onPressFeedback}
        />
        <SecondaryButton
          text='Log out'
          onPress={onPressLogOut}
        />
      </ButtonContainer>
    </Container>
  );
};

export default SettingsSignedIn;