import _ from 'lodash';
import LZString from 'lz-string';
import { DRUM_SOUND_CHANNELS, DRUM_SOUND_RATE } from '../../constants';
import { makeSound } from '../player/player.web';
import cleanbass from './bass/clean-bass.wav.json';
import doombass from './bass/doom-bass.wav.json';
import farbass from './bass/far-bass.wav.json';
import megabass from './bass/mega-bass.wav.json';
import rattlebass from './bass/rattle-bass.wav.json';
import chorusclap from './claps/chorus-clap.wav.json';
import crispclap from './claps/crisp-clap.wav.json';
import smackclap from './claps/smack-clap.wav.json';
import synthclap from './claps/synth-clap.wav.json';
import tripleclap from './claps/triple-clap.wav.json';
import brushhat from './hihats/brush-hat.wav.json';
import delicatehat from './hihats/delicate-hat.wav.json';
import hardhat from './hihats/hard-hat.wav.json';
import jazzhat from './hihats/jazz-hat.wav.json';
import woodhat from './hihats/wood-hat.wav.json';
import airkick from './kicks/air-kick.wav.json';
import brushkick from './kicks/brush-kick.wav.json';
import dirtykick from './kicks/dirty-kick.wav.json';
import fastkick from './kicks/fast-kick.wav.json';
import stonekick from './kicks/stone-kick.wav.json';
import brushsnare from './snares/brush-snare.wav.json';
import cleansnare from './snares/clean-snare.wav.json';
import dubsnare from './snares/dub-snare.wav.json';
import quicksnare from './snares/quick-snare.wav.json';
import spacesnare from './snares/space-snare.wav.json';

const drumData = [
  ['bass', 'clean-bass', cleanbass],
  ['bass', 'doom-bass', doombass],
  ['bass', 'far-bass', farbass],
  ['bass', 'mega-bass', megabass],
  ['bass', 'rattle-bass', rattlebass],
  ['claps', 'chorus-clap', chorusclap],
  ['claps', 'crisp-clap', crispclap],
  ['claps', 'smack-clap', smackclap],
  ['claps', 'synth-clap', synthclap],
  ['claps', 'triple-clap', tripleclap],
  ['hihats', 'brush-hat', brushhat],
  ['hihats', 'delicate-hat', delicatehat],
  ['hihats', 'hard-hat', hardhat],
  ['hihats', 'jazz-hat', jazzhat],
  ['hihats', 'wood-hat', woodhat],
  ['kicks', 'air-kick', airkick],
  ['kicks', 'brush-kick', brushkick],
  ['kicks', 'dirty-kick', dirtykick],
  ['kicks', 'fast-kick', fastkick],
  ['kicks', 'stone-kick', stonekick],
  ['snares', 'brush-snare', brushsnare],
  ['snares', 'clean-snare', cleansnare],
  ['snares', 'dub-snare', dubsnare],
  ['snares', 'quick-snare', quicksnare],
  ['snares', 'space-snare', spacesnare],
];

const folders = [];
for (let i = 0; i < drumData.length; i++) {
  const [folderName, drumId, { data: compressed, name }] = drumData[i];
  let folderExists = false;
  for (let i = 0; i < folders.length; i++) {
    if (folders[i].name === folderName) {
      folderExists = true;
    }
  }
  if (!folderExists) {
    folders.push({ name: folderName, children: [] });
  }
  let folderIndex;
  for (let i = 0; i < folders.length; i++) {
    if (folders[i].name === folderName) {
      folderIndex = i;
    }
  }
  const data = new Int16Array(JSON.parse(LZString.decompressFromBase64(compressed)));
  const sound = makeSound(data, DRUM_SOUND_RATE, DRUM_SOUND_CHANNELS);
  folders[folderIndex].children.push({
    id: drumId,
    name,
    sound,
    data,
  });
}

export const drumSounds = folders;

export const defaultDrumMap = [
  'air-kick',
  'brush-snare',
  'jazz-hat',
  'triple-clap',
  'clean-bass',
  'doom-bass',
  'clean-snare',
  'brush-hat',
  'chorus-clap',
  'brush-kick',
  'far-bass',
  'dub-snare',
  'delicate-hat',
  'synth-clap',
  'dirty-kick',
  'mega-bass',
  'quick-snare',
  'hard-hat',
  'crisp-clap',
  'fast-kick',
  'rattle-bass',
  'space-snare',
  'wood-hat',
  'smack-clap',
  'stone-kick',
];

const drumIdMap = {};
const populateDrumIdMap = (node) => {
  if (node.children) {
    node.children.forEach(populateDrumIdMap);
  } else {
    drumIdMap[node.id] = node;
  }
};
drumSounds.forEach(populateDrumIdMap);

export const getDrumById = (id) => drumIdMap[id];