import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { loadLocalRecordings } from '../recordings/recordingStorage';
import { mergeRecordingUpdate } from '../recordings/recordingUtil';
import { createRecordingOnCloud, deleteRecordingOnCloud, getRecordingFromCloud, updateRecordingOnCloud } from './apiActions/recordingActions';

const initialState = {
  isStateLoaded: false,
  isProcessing: false,
  focusedRecordingIndex: -1,
  recordings: []
};

export const loadState = createAsyncThunk(
  'recordings/loadState',
  async () => {
    const recordings = await loadLocalRecordings();
    return { recordings };
  }
);

export const recordingsSlice = createSlice({
  name: 'recordings',
  initialState,
  reducers: {
    setIsProcessing: (state, action) => {
      state.isProcessing = action.payload;
    },
    selectRecording: (state, action) => {
      const { index } = action.payload;
      state.focusedRecordingIndex = index;
    },
    createRecordingInMemory: (state, action) => {
      const recording = action.payload;
      state.focusedRecordingIndex = state.recordings.length;
      state.recordings.push(recording);
    },
    updateRecordingInMemory: (state, action) => {
      const { update, index } = action.payload;
      state.recordings[index] = mergeRecordingUpdate(state.recordings[index], update);
    },
    deleteRecordingInMemory: (state, action) => {
      const { index, deletedRecording } = action.payload;
      state.recordings[index] = deletedRecording;
    }
  },
  extraReducers: {
    [loadState.fulfilled]: (state, action) => {
      state.recordings = action.payload.recordings;
      state.isStateLoaded = true;
    },
    [getRecordingFromCloud.fulfilled]: (state, action) => {
      const result = _.find(state.recordings, { storageId: action.payload.storageId });
      if (!result) {
        console.log('Retrieved from cloud. ' + action.payload._id);
        state.recordings.push(action.payload);
      } else {
        console.log('Retrieved dupe from cloud. Skipping...');
      }
    },
    [createRecordingOnCloud.fulfilled]: (state, action) => {
      console.log('Created on cloud.');
      const { _id, storageId } = action.payload;
      for (let i = 0; i < state.recordings.length; i++) {
        if (state.recordings[i].storageId === storageId) {
          state.recordings[i]._id = _id;
          state.recordings[i].isBackedUp = true;
        }
      }
    },
    [updateRecordingOnCloud.fulfilled]: (state, action) => {
      console.log('Updated on cloud.');
      const { storageId } = action.payload;
      for (let i = 0; i < state.recordings.length; i++) {
        if (state.recordings[i].storageId === storageId) {
          state.recordings[i].isBackedUp = true;
        }
      }
    },
    [deleteRecordingOnCloud.fulfilled]: (state, action) => {
      console.log('Deleted on cloud.');
      const { storageId } = action.payload;
      for (let i = 0; i < state.recordings.length; i++) {
        if (state.recordings[i].storageId === storageId) {
          state.recordings[i].isBackedUp = true;
        }
      }
    },
  }
});

export const {
  setIsProcessing,
  selectRecording,
  createRecordingInMemory,
  updateRecordingInMemory,
  deleteRecordingInMemory,
} = recordingsSlice.actions;

export default recordingsSlice.reducer;
