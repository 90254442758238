import { buildMidiBytes } from '../audio/midiBuilder';
import { buildWavBytes } from '../audio/wavBuilder';
import { EXPORT_FILE_TYPES } from '../constants';
import { writeFile } from './fileWriter';

export const exportRecording = (recording, exportType) => {
  const isMidi = (exportType === EXPORT_FILE_TYPES.MIDI);
  const isWav = (exportType === EXPORT_FILE_TYPES.WAV);
  if (!isMidi && !isWav) {
    throw Error('Invalid export file type.');
  }

  let bytes;
  if (isWav) {
    const { data, rate, channels } = recording.beatbotData.outputAudio;
    const dataArray = new Int16Array(data);
    bytes = buildWavBytes(dataArray, rate, channels);
  } else if (isMidi) {
    const { beatStarts, beatEnds, classifications, outputAudio: { rate }} = recording.beatbotData;
    bytes = buildMidiBytes(beatStarts, beatEnds, classifications, rate);
  }

  const extension = isWav ? '.wav' : '.mid';
  const fileName = recording.name + extension;

  writeFile(bytes, fileName);
};
