import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import { getDrumById } from '../../audio/drumSounds/drumSounds';
import { COLORS, DRUM_PICKER_BUTTON_HEIGHT, FONT_BOLD, SMALL_FONT_SIZE, SMALL_MARGIN, STANDARD_MARGIN, TITLE_FONT } from '../../constants';

const Container = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-horizontal: ${SMALL_MARGIN / 2}px;
`;

const DrumContainer = styled.View`
  width: 200px;
  max-width: 40%;
  margin-horizontal: ${SMALL_MARGIN / 2}px;
  margin-bottom: ${SMALL_MARGIN}px;
`;

const DrumInner = styled.View`
  width: 100%;
  height: ${DRUM_PICKER_BUTTON_HEIGHT}px;
  border-radius: ${DRUM_PICKER_BUTTON_HEIGHT / 2}px;
  background-color: ${props => props.color};
  justify-content: center;
  align-items: center;
`;

const DrumText = styled.Text`
  font-size: ${SMALL_FONT_SIZE}px;
  font-family: ${FONT_BOLD};
  color: ${COLORS.BACKGROUND};
`;

const DrumPicker = ({
  classifications,
  drumMap,
  style,
  showModal,
  setSelectedClassification,
}) => {

  const uniqueClassifications = [];
  for (let i = 0; i < classifications.length; i++) {
    if (!uniqueClassifications.includes(classifications[i])) {
      uniqueClassifications.push(classifications[i]);
    }
  }

  const drumIds = uniqueClassifications.map((classification) => {
    const drumIndex = classification % drumMap.length;
    return drumMap[drumIndex]; 
  });
  const drums = drumIds.map(getDrumById);

  return (
    <Container style={style}>
      {drums.map((drum, index) => {
        const colorIndex = uniqueClassifications[index] % COLORS.HIGHLIGHTS.length;
        const color = COLORS.HIGHLIGHTS[colorIndex];
        const onPress = () => {
          setSelectedClassification(uniqueClassifications[index]);
          showModal();
        };
        return (
          <DrumContainer key={`drum-${index}`}>
            <TouchableOpacity onPress={onPress}>
              <DrumInner color={color}>
                <DrumText>
                  {drum.name.toLowerCase()}
                </DrumText>
              </DrumInner>
            </TouchableOpacity>
          </DrumContainer>
        );
      })}
    </Container>
  );
};

export default DrumPicker;