import { Formik } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';
import * as Yup from 'yup';
import { COLORS, FONT_BOLD, SMALL_MARGIN, STANDARD_FONT_SIZE, STANDARD_MARGIN, TITLE_FONT, TITLE_FONT_SIZE } from '../constants';
import { sendFeedback } from '../redux/apiActions/feedbackActions';
import { hideFeedbackModal } from '../redux/modalsSlice';
import { PrimaryButton, SecondaryButton } from './Button';
import Modal from './Modal';
import TextInput from './TextInput';

const Container = styled.View`
  padding: ${STANDARD_MARGIN}px;
`;

const Input = styled(TextInput)`
  height: 180px;
`;

const Error = styled.Text`
  margin-bottom: ${SMALL_MARGIN}px;
  font-size: ${STANDARD_FONT_SIZE}px;
  font-family: ${FONT_BOLD};
  color: ${COLORS.HIGHLIGHTS[0]};
  text-align: left;
  width: 100%;
  max-width: 500px;
`;

const Divider = styled.View`
  height: 1px;
  opacity: 0.2;
  background-color: ${COLORS.DARK};
  margin-vertical: ${STANDARD_MARGIN}px;
`;

const Buttons = styled.View`
  flex-direction: row;
`;

const CancelButton = styled(SecondaryButton)`
  flex: 1;
  margin-right: ${SMALL_MARGIN / 2}px;
`;

const ConfirmButton = styled(PrimaryButton)`
  flex: 1;
  margin-left: ${SMALL_MARGIN / 2}px;
`;

const ThankYou = styled.Text`
  font-family: ${TITLE_FONT};
  font-size: ${TITLE_FONT_SIZE}px;
  color: ${COLORS.DARK};
  margin-bottom: ${SMALL_MARGIN}px;
  text-align: center;
`;

const DoneButton = styled(PrimaryButton)`
`;

const FeedbackSchema = Yup.object().shape({
  feedback: Yup.string()
    .min(1, 'Feedback too short')
    .required('Feedback too short')
});

const ModalFeedback = () => {

  const isVisible = useSelector(state => state.modals.feedback.isVisible);

  const dispatch = useDispatch();

  const [isThankyou, setIsThankYou] = useState(false);

  const formikProps = {
    initialValues: {
      feedback: ''
    },
    validationSchema: FeedbackSchema,
    validationOnBlur: false,
    validationOnChange: false,
    onSubmit: async (values, { setErrors }) => {
      const { feedback } = values;
      const { error } = await dispatch(sendFeedback(feedback));
      if (error) {
        setErrors({ feedback: 'Error. Unable to send feedback at this time' });
      } else {
        setIsThankYou(true);
      }
    }
  };

  const onPressCancel = () => {
    dispatch(hideFeedbackModal());
    setTimeout(() => setIsThankYou(false), 500);
  };

  return (
    <Modal
      isVisible={isVisible}
      close={onPressCancel}
    >
      <Container>
        {isThankyou ? (
          <>
            <ThankYou>
              Thank you for your feedback!
            </ThankYou>
            <DoneButton
              text='Done'
              onPress={onPressCancel}
            />
          </>
        ) : (
          <Formik {...formikProps}>
            {({ handleChange, handleBlur, handleSubmit, values, errors }) => (
              <>
                {!errors.feedback ? null : <Error>{errors.feedback}</Error>}
                <Input
                  placeholder='Feedback'
                  value={values.feedback}
                  multiline={true}
                  onChange={handleChange('feedback')}
                  onBlur={handleBlur('feedback')}
                />
                <Divider />
                <Buttons>
                  <CancelButton
                    text='Cancel'
                    onPress={onPressCancel}
                  />
                  <ConfirmButton
                    text='Send'
                    onPress={handleSubmit}
                  />
                </Buttons>
              </>
            )}
          </Formik>
        )}
      </Container>
    </Modal>
  );
};

export default ModalFeedback;
