import AsyncStorage from '@react-native-async-storage/async-storage';
import _ from 'lodash';
import { INPUT_AUDIO_STORAGE_KEY, RECORDINGS_STORAGE_KEY, RECORDING_STORAGE_KEY } from '../constants';
import * as storage from '../util/storage';

const makeRecordingKey = (id) => {
  return (
    RECORDINGS_STORAGE_KEY + '.' +
    RECORDING_STORAGE_KEY + '.' + id
  );
};

const makeInputAudioKey = (id) => {
  return (
    RECORDINGS_STORAGE_KEY + '.' +
    INPUT_AUDIO_STORAGE_KEY + '.' + id
  );
};

const getIdFromKey = (key) => {
  const prefix = RECORDINGS_STORAGE_KEY + '.';
  const startIndex = key.indexOf('.', prefix.length) + 1;
  return key.substring(startIndex, key.length);
};

const isCertainKey = (keyMaker) => (key) => {
  const expectedPrefix = keyMaker('');
  const actualPrefix = key.substring(0, expectedPrefix.length);
  return expectedPrefix === actualPrefix;
};
const isRecordingKey = isCertainKey(makeRecordingKey);
const isInputAudioKey = isCertainKey(makeInputAudioKey);

export const loadLocalRecordings = async () => {
  const allKeys = await AsyncStorage.getAllKeys();
  const recordingKeys = allKeys.filter(isRecordingKey);
  const inputAudioKeys = allKeys.filter(isInputAudioKey);
  const recordings = await storage.loadAll(recordingKeys);
  const inputAudios = await storage.loadAll(inputAudioKeys);

  for (let i = 0; i < recordings.length; i++) {
    const recordingId = recordings[i].storageId;
    for (let j = 0; j < inputAudioKeys.length; j++) {
      const inputAudioId = getIdFromKey(inputAudioKeys[j]);
      if (recordingId === inputAudioId) {
        recordings[i].inputAudio = inputAudios[j];
      }
    }
  }

  return recordings;
};

export const createRecordingLocally = async (recording) => {
  const recordingKey = makeRecordingKey(recording.storageId);
  const inputAudioKey = makeInputAudioKey(recording.storageId);
  await storage.save(recordingKey, _.omit(recording, ['beatbotData.outputAudio', 'inputAudio']));
  await storage.save(inputAudioKey, recording.inputAudio);
};

export const updateRecordingLocally = async (recording) => {
  const key = makeRecordingKey(recording.storageId);
  await storage.save(key, _.omit(recording, ['beatbotData.outputAudio', 'inputAudio']));
};

export const deleteRecordingLocally = async (deletedRecording) => {
  const recordingKey = makeRecordingKey(deletedRecording.storageId);
  const inputAudioKey = makeInputAudioKey(deletedRecording.storageId);
  await storage.save(recordingKey, deletedRecording);
  await AsyncStorage.removeItem(inputAudioKey);
};