import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/native';
import * as Yup from 'yup';
import { PrimaryButton } from '../../components/Button';
import TextInput from '../../components/TextInput';
import { COLORS, FONT_BOLD, MAX_PASSWORD_LENGTH, MIN_PASSWORD_LENGTH, SMALL_MARGIN, STANDARD_FONT_SIZE, STANDARD_MARGIN } from '../../constants';
import { syncRecordingsWithCloud } from '../../recordings/recordingSync';
import { logIn } from '../../redux/apiActions/authTokenActions';
import { signUp } from '../../redux/apiActions/userActions';
import { navigateBack } from '../../util/navigate';
import SignInVisual from './SignInVisual';

const Container = styled.View`
  background-color: ${COLORS.BACKGROUND};
  flex: 1;
  padding-bottom: ${STANDARD_MARGIN}px;
  align-items: center;
  justify-content: space-around;
`;

const InputContainer = styled.View`
  padding-horizontal: ${STANDARD_MARGIN}px;
  width: 100%;
  align-items: center;
`;

const Input = styled(TextInput)`
  margin-bottom: ${SMALL_MARGIN}px;
`;

const Error = styled.Text`
  margin-bottom: ${SMALL_MARGIN}px;
  font-size: ${STANDARD_FONT_SIZE}px;
  font-family: ${FONT_BOLD};
  color: ${COLORS.HIGHLIGHTS[0]};
  text-align: left;
  width: 100%;
  max-width: 500px;
`;

const SubmitButton = styled(PrimaryButton)`
  margin-bottom: ${SMALL_MARGIN}px;
  background-color: ${props => props.color};
`;

const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
  password: Yup.string()
    .min(MIN_PASSWORD_LENGTH, `Password must be at least ${MIN_PASSWORD_LENGTH} characters`)
    .max(MAX_PASSWORD_LENGTH, `Password must be less than ${MAX_PASSWORD_LENGTH} characters`)
    .required('Required'),
});

const SignIn = ({ color, text, emailCallback, serverError }) => {

  const formikProps = {
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: SignInSchema,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values, { setErrors }) => {
      const { email, password } = values;
      const { error } = await emailCallback(email, password);
      if (error) {
        setErrors({ api: serverError })
      } else {
        navigateBack();
        await syncRecordingsWithCloud();
      }
    }
  };

  return (
    <Container>
      <SignInVisual color={color} />
      <Formik {...formikProps}>
        {({ handleChange, handleBlur, handleSubmit, values, errors }) => (
          <InputContainer>
            {!errors.api ? null : <Error>{errors.api}</Error>}
            {!errors.email ? null : <Error>{errors.email}</Error>}
            <Input
              placeholder='email'
              keyboardType='email-address'
              value={values.email}
              onChange={handleChange('email')}
              onBlur={handleBlur('email')}
            />
            {!errors.password ? null : <Error>{errors.password}</Error>}
            <Input
              placeholder='password'
              secureTextEntry={true}
              value={values.password}
              onChange={handleChange('password')}
              onBlur={handleBlur('password')}
            />
            <SubmitButton
              color={color}
              text={text}
              onPress={handleSubmit}
            />
          </InputContainer>
        )}
      </Formik>
    </Container>
  );
};

export const SignUp = () => {
  const dispatch = useDispatch();
  return (
    <SignIn
      color={COLORS.HIGHLIGHTS[2]}
      text='Sign up'
      emailCallback={(email, password) => dispatch(signUp({ email, password }))}
      serverError='Error creating an account (that email may be in use already)'
    />
  );
};

export const LogIn = () => {
  const dispatch = useDispatch();
  return (
    <SignIn
      color={COLORS.HIGHLIGHTS[1]}
      text='Log in'
      emailCallback={(email, password) => dispatch(logIn({ email, password }))}
      serverError='Could not find an account with that email / password'
    />
  );
};