import { apiGet } from '../redux/apiActions/api';
import { createRecordingOnCloud, deleteRecordingOnCloud, getRecordingFromCloud, updateRecordingOnCloud } from '../redux/apiActions/recordingActions';
import { dispatch, getState } from '../redux/store';

export const syncRecordingsWithCloud = async () => {
  await uploadRecordings();
  await downloadRecordings();
};

const uploadRecordings = async () => {
  const recordings = getState().recordings.recordings;
  const notBackedUpRecordings = recordings.filter((recording) => !recording.isBackedUp);
  for (const recording of notBackedUpRecordings) {
    const { _id, isDeleted } = recording;
    if (!_id && !isDeleted) {
      console.log('Sync: created recording on cloud.');
      await dispatch(createRecordingOnCloud(recording));
    } else if (_id && !isDeleted) {
      console.log('Sync: updated recording on cloud. ' + _id);
      await dispatch(updateRecordingOnCloud(recording));
    } else if (_id && isDeleted) {
      console.log('Sync: deleted recording on cloud.' + _id);
      await dispatch(deleteRecordingOnCloud(recording));
    }
  }
};

const downloadRecordings = async () => {
  const { userId, isSignedIn } = getState().user;
  if (!isSignedIn) {
    return;
  }
  try {
    const { recordingSummaries } = await apiGet(`/users/${userId}/recordings/summary`);
    const recordingIds = getState().recordings.recordings.map((recording) => recording._id);
    for (const { _id } of recordingSummaries) {
      if (!recordingIds.includes(_id)) {
        const { error } = await dispatch(getRecordingFromCloud(_id));
        if (error) {
          console.log(error);
        } else {
          console.log('Sync: downloaded recording from cloud.');
        }
      }
    }
  } catch (e) {}
};

