import { FontAwesome5 } from '@expo/vector-icons';
import { useState } from 'react';
import styled from 'styled-components/native';
import { COLORS, DRUM_SOUND_CHANNELS, DRUM_SOUND_RATE, FONT_BOLD, LARGE_FONT_SIZE, SMALL_MARGIN, STANDARD_FONT_SIZE, STANDARD_MARGIN, TITLE_FONT } from '../../constants';

const Container = styled.View`
  align-items: flex-start;
`;

const TitleContainer = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  margin-bottom: ${SMALL_MARGIN / 2}px;
  margin-top: ${STANDARD_MARGIN / 2}px;
`;

const Title = styled.Text`
  font-family: ${TITLE_FONT};
  font-size: ${LARGE_FONT_SIZE}px;
  color: ${COLORS.DARK};
  margin-left: ${STANDARD_MARGIN}px;
`;

const DrumSound = styled.TouchableOpacity`
  margin-bottom: ${SMALL_MARGIN / 2}px;
  margin-top: ${SMALL_MARGIN / 2}px;
  margin-left: ${28 + SMALL_MARGIN}px;
  background-color: ${props => props.isSelected ? props.color : '#00000000'};
  border-radius: 
  height: 30px;
  border-radius: 15px;
  justify-content: center;
`;

const DrumSoundText = styled.Text`
  font-family: ${FONT_BOLD};
  font-size: ${STANDARD_FONT_SIZE}px;
  color: ${props => props.isSelected ? COLORS.BACKGROUND : COLORS.DARK}
  padding-horizontal: ${SMALL_MARGIN}px;
`;

const DrumPickerModalFolder = ({
  drumSoundFolder,
  selectedDrumId,
  setSelectedDrumId,
  color,
}) => {

  let initialIsOpened = false;
  for (let i = 0; i < drumSoundFolder.children.length; i++) {
    if (drumSoundFolder.children[i].id === selectedDrumId) {
      initialIsOpened = true;
    }
  }

  const [isOpened, setIsOpened] = useState(initialIsOpened);

  const onPressTitle = () => {
    setIsOpened(!isOpened);
  };

  return (
    <Container>
      <TitleContainer onPress={onPressTitle}>
        <FontAwesome5
          color={COLORS.DARK}
          name={isOpened ? 'chevron-down' : 'chevron-right'}
          size={20}
          solid
        />
        <Title>
          {drumSoundFolder.name.toLowerCase()}
        </Title>
      </TitleContainer>
      {!isOpened ? null : drumSoundFolder.children.map((drumSound) => {
        const isSelected = drumSound.id === selectedDrumId;
        const onPressDrum = () => {
          drumSound.sound.play();
          setSelectedDrumId(drumSound.id);
        };
        return (
          <DrumSound
            key={drumSound.id}
            isSelected={isSelected}
            color={color}
            onPress={onPressDrum}
          >
            <DrumSoundText isSelected={isSelected}>
              {drumSound.name.toLowerCase()}
            </DrumSoundText>
          </DrumSound>
        );
      })}
    </Container>
  );
};

export default DrumPickerModalFolder;