import { FontAwesome5 } from '@expo/vector-icons';
import { useState } from 'react';
import { TouchableOpacity } from 'react-native';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/native';
import * as recorder from '../../audio/recorder/recorder';
import * as beatbot from '../../beatbot/beatbot';
import WaveVisual from '../../components/WaveVisual';
import { COLORS, FONT, FONT_BOLD, NAV_BAR_HEIGHT, RECORDING_BUTTON_ICON_SIZE, RECORDING_BUTTON_SIZE, RECORDING_TIME_FONT_SIZE, RECORD_WAVE_VISUAL_COLORS, SMALL_MARGIN, STANDARD_FONT_SIZE, STANDARD_MARGIN } from '../../constants';
import { createRecording } from '../../recordings/recordingEditor';
import { showMessageModal } from '../../redux/modalsSlice';
import { setIsProcessing } from '../../redux/recordingsSlice';
import { navigate } from '../../util/navigate';
import { formatSeconds } from '../../util/stringFormatter';

const Container = styled.View`
  flex: 1;
  background-color: ${COLORS.BACKGROUND};
`;

const Content = styled.View`
  margin-bottom: ${NAV_BAR_HEIGHT + STANDARD_MARGIN}px;
  padding-bottom: ${STANDARD_MARGIN}px;
  flex: 1;
  justify-content: space-around;
  align-items: center;
`;

const Time = styled.Text`
  padding-horizontal: ${STANDARD_MARGIN}px;
  font-family: ${FONT};
  font-size: ${RECORDING_TIME_FONT_SIZE}px;
  line-height: ${RECORDING_TIME_FONT_SIZE}px;
  color: ${COLORS.DARK};
  margin-top: 24px;
`;

const RecordingContainer = styled.View`
  align-items: center;
  padding-top: 24px;
`;

const RecordingText = styled.Text`
  margin-bottom: ${SMALL_MARGIN}px;
  font-size: ${STANDARD_FONT_SIZE}px;
  line-height: ${STANDARD_FONT_SIZE}px;
  text-align: center;
  color: ${COLORS.DARK};
  font-family: ${FONT_BOLD};
`;

const RecordingButton = styled.View`
  background-color: ${COLORS.DARK};
  align-items: center;
  justify-content: center;
  height: ${RECORDING_BUTTON_SIZE}px;
  width: ${RECORDING_BUTTON_SIZE}px;
  border-radius: ${RECORDING_BUTTON_SIZE / 2}px;
`;

const RecordingIcon = styled(FontAwesome5)`
`;

let interval;
let counter = 0;

const Record = () => {
  
  const dispatch = useDispatch();

  const [seconds, setSeconds] = useState(0);
  const [isRecording, setIsRecording] = useState(false);

  const onPressRecord = () => {
    if (!isRecording) {
      startRecording();
    } else {
      stopRecording();
    }
  };

  const startRecording = async () => {
    const hasPermissions = await recorder.hasPermissions();
    if (!hasPermissions) {
      dispatch(showMessageModal('Please enable microphone access to create a recording.'));
      return;
    }
    const isRecorderInitialzed = recorder.getIsInitialized();
    if (!isRecorderInitialzed) {
      const initSuccess = await recorder.init();
      if (!initSuccess) {
        dispatch(showMessageModal('Error initializing recorder.'));
        return;
      }
    }
    recorder.setOnDataAvailable((buffer) => beatbot.record(buffer));
    recorder.setOnStop(async () => {
      beatbot.doneRecording();
      await createRecording();
      dispatch(setIsProcessing(false));
    });
    
    beatbot.init(recorder.getSampleRate(), recorder.getNumChannels());
    recorder.record();
    interval = setInterval(() => {
      counter++;
      setSeconds(counter);
    }, 1000);
    setIsRecording(true);
  };

  const stopRecording = () => {
    if (counter < 1) {
      recorder.setOnStop(() => null);
    } else {
      dispatch(setIsProcessing(true));
      navigate('Editor');    
    }
    recorder.stop();
    clearInterval(interval);
    setIsRecording(false);
    counter = 0;
    setSeconds(counter);
  };

  return (
    <Container>
      <Content>
        <Time>
          {formatSeconds(seconds)}
        </Time>
        <WaveVisual lineColors={RECORD_WAVE_VISUAL_COLORS} isAnimating={isRecording} />
        <RecordingContainer>
          <RecordingText>
            {isRecording ? 'Recording...' : 'Ready to record'}
          </RecordingText>
          <TouchableOpacity onPress={onPressRecord}>
            <RecordingButton>
              {isRecording
                ? <RecordingIcon name='square' size={RECORDING_BUTTON_ICON_SIZE} color={COLORS.BACKGROUND} solid />
                : <RecordingIcon name='circle' size={RECORDING_BUTTON_ICON_SIZE} color={COLORS.BACKGROUND} solid />
              }
            </RecordingButton>
          </TouchableOpacity>
        </RecordingContainer>
      </Content>
    </Container>
  );
};

export default Record;
