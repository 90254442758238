import { useDispatch } from 'react-redux';
import { showFeedbackModal } from '../redux/modalsSlice';
import OverflowMenu from './OverflowMenu';

const FeedbackOverflowMenu = () => {
  
  const dispatch = useDispatch();

  return (
    <OverflowMenu 
      options={[
        {
          text: 'Send feedback',
          onPress: () => {
            dispatch(showFeedbackModal());
          },
        },
      ]}
    />
  );
};

export default FeedbackOverflowMenu;