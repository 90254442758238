import React, { useState, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components/native';
import { ANIMATION_USE_NATIVE_DRIVER, COLORS, LIBRARY_VISUAL_DOT_MARGIN, LIBRARY_VISUAL_DOT_SIZE, SMALL_MARGIN } from '../../constants';
import { Animated, Easing } from 'react-native';

const Container = styled.View`
  width: 100%;
  justify-content: center;
  margin-top: ${SMALL_MARGIN}px;
`;

const Dots = styled.View`
  width: ${props => props.width}px;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  height: ${LIBRARY_VISUAL_DOT_SIZE * 3}px;
`;

const Dot = styled.View`
  width: ${LIBRARY_VISUAL_DOT_SIZE}px;
  height: ${LIBRARY_VISUAL_DOT_SIZE}px;
  border-radius: ${LIBRARY_VISUAL_DOT_SIZE / 2}px;
  background-color: ${props => props.color};
`;

const AnimatedDot = Animated.createAnimatedComponent(Dot);

const updateDotColors = (colors, beatStart, beatEnd, numDots, classification, volumeLength) => {
  const colorIndex = classification % COLORS.HIGHLIGHTS.length;
  const color = COLORS.HIGHLIGHTS[colorIndex];
  const dotStartIndex = Math.ceil(beatStart / (volumeLength - 1) * (numDots - 1));
  let dotEndIndex = Math.floor(beatEnd / (volumeLength - 1) * (numDots - 1));
  dotEndIndex = Math.max(dotEndIndex, dotStartIndex);
  for (let i = dotStartIndex; i <= dotEndIndex; i++) {
    colors[i] = color;
  }
};

const LibraryRecordingVisual = ({
  beatStarts,
  beatEnds,
  classifications,
  volumeLength,
  duration,
  isPlaying,
}) => {

  const [dimensions, setDimensions] = useState(null);
  const onLayout = (event) => {
    const { width, height } = event.nativeEvent.layout;
    setDimensions({ width, height });
  };

  const animatedHeights = useRef([]).current;

  const { dotColors, dotsWidth, numDots } = useMemo(() => {
    if (!dimensions) {
      return { dotColors: [], dotsWidth: '100%', numDots: 0 };
    }
    
    const numDots = Math.floor((dimensions.width - LIBRARY_VISUAL_DOT_MARGIN) / (LIBRARY_VISUAL_DOT_SIZE + LIBRARY_VISUAL_DOT_MARGIN));
    const dotsWidth = numDots * LIBRARY_VISUAL_DOT_SIZE + (numDots + 1) * LIBRARY_VISUAL_DOT_MARGIN;
   
    animatedHeights.slice(0, animatedHeights.length);
    for (let i = 0; i < numDots; i++) {
      animatedHeights.push(new Animated.Value(LIBRARY_VISUAL_DOT_SIZE));
    }

    const dotColors = [];
    for (let i = 0; i < numDots; i++) {
      dotColors.push(COLORS.LIGHT);
    }
    for (let i = 0; i < beatStarts.length; i++) {
      updateDotColors(dotColors, beatStarts[i], beatEnds[i], numDots, classifications[i], volumeLength);
    }
    
    return { dotColors, dotsWidth, numDots };
  }, [dimensions, beatStarts, beatEnds, classifications, volumeLength]);

  useEffect(() => {
    if (isPlaying) {
      startAnimation();
    } else {
      stopAnimation();
    }
  }, [isPlaying]);

  const [animation, setAnimation] = useState(null);

  const startAnimation = () => {
    const msPerDot = duration / numDots * 1000;
    const animations = [];
    for (let i = 0; i < numDots; i++) {
      const sequence = [{
        toValue: LIBRARY_VISUAL_DOT_SIZE,
        duration: i * msPerDot
      }, {
        toValue: LIBRARY_VISUAL_DOT_SIZE * 3,
        duration: 2 * msPerDot
      }, {
        toValue: LIBRARY_VISUAL_DOT_SIZE,
        duration: 2 * msPerDot 
      }]
        .filter((item) => item.duration > 0)
        .map((item) => Animated.timing(animatedHeights[i], {
          ...item,
          useNativeDriver: ANIMATION_USE_NATIVE_DRIVER,
          easing: Easing.linear
        }));
      animations.push(Animated.sequence(sequence));
    }
    const animation = Animated.parallel(animations);
    animation.start();
    setAnimation(animation);
  };

  const stopAnimation = () => {
    if (animation) {
      animation.stop();
      const animations = [];
      for (let i = 0; i < numDots; i++) {
        animations.push(Animated.timing(animatedHeights[i], {
          toValue: LIBRARY_VISUAL_DOT_SIZE,
          duration: 0,
          useNativeDriver: ANIMATION_USE_NATIVE_DRIVER
        }));
      }
      Animated.parallel(animations).start();
    }
  };

  return (
    <Container onLayout={onLayout}>
      <Dots width={dotsWidth}>
        {dotColors.map((color, index) => (
          <AnimatedDot
            key={`dot-${index}`}
            color={color}
            style={{ height: animatedHeights[index] }}
          />
        ))}
      </Dots>
    </Container>
  );
};

export default React.memo(LibraryRecordingVisual);