import { createSlice } from '@reduxjs/toolkit';
import { logIn } from './apiActions/authTokenActions';
import { sendFeedback } from './apiActions/feedbackActions';
import { signUp } from './apiActions/userActions';

const initialState = {
  isLoading: false,
};

const setIsLoading = (value) => (state) => {
  state.isLoading = value;
};

export const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    showLoading: setIsLoading(true),
    hideLoading: setIsLoading(false),
  },
  extraReducers: {
    
    [logIn.pending]: setIsLoading(true),
    [logIn.fulfilled]: setIsLoading(false),
    [logIn.rejected]: setIsLoading(false),

    [signUp.pending]: setIsLoading(true),
    [signUp.rejected]: setIsLoading(false),
    [signUp.fulfilled]: setIsLoading(false),

    [sendFeedback.pending]: setIsLoading(true),
    [sendFeedback.rejected]: setIsLoading(false),
    [sendFeedback.fulfilled]: setIsLoading(false),
  }
});

export const { 
  showLoading,
  hideLoading,
} = loadingSlice.actions;

export default loadingSlice.reducer;