import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/native';
import { COLORS, SMALL_MARGIN, STANDARD_MARGIN } from '../constants';
import { hidePromptModal } from '../redux/modalsSlice';
import { PrimaryButton, SecondaryButton } from './Button';
import Modal from './Modal';
import TextInput from './TextInput';

const Container = styled.View`
  padding: ${STANDARD_MARGIN}px;
`;

const Input = styled(TextInput)`
`;

const Divider = styled.View`
  height: 1px;
  opacity: 0.2;
  background-color: ${COLORS.DARK};
  margin-vertical: ${STANDARD_MARGIN}px;
`;

const Buttons = styled.View`
  flex-direction: row;
`;

const CancelButton = styled(SecondaryButton)`
  flex: 1;
  margin-right: ${SMALL_MARGIN / 2}px;
`;

const ConfirmButton = styled(PrimaryButton)`
  flex: 1;
  margin-left: ${SMALL_MARGIN / 2}px;
`;

const ModalPrompt = () => {

  const {
    isVisible,
    initialValue,
    placeholder,
    confirmText,
    onConfirm,
    onCancel,
  } = useSelector(state => state.modals.prompt);

  const dispatch = useDispatch();

  const [value, setValue] = useState('');

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const onChangeValue = (event) => setValue(event.target.value);

  const onPressConfirm = () => {
    dispatch(hidePromptModal());
    onConfirm(value);
    setValue('');
  };

  const onPressCancel = () => {
    dispatch(hidePromptModal());
    onCancel();
    setValue('');
  };

  return (
    <Modal
      isVisible={isVisible}
      close={onPressCancel}
    >
      <Container>
        <Input
          placeholder={placeholder}
          value={value}
          onChange={onChangeValue}
        />
        <Divider />
        <Buttons>
          <CancelButton
            text='Cancel'
            onPress={onPressCancel}
          />
          <ConfirmButton
            text={confirmText}
            onPress={onPressConfirm}
          />
        </Buttons>
      </Container>
    </Modal>
  );
};

export default ModalPrompt;
