import { FontAwesome5 } from '@expo/vector-icons';
import styled from 'styled-components/native';
import { PrimaryButton, SecondaryButton } from '../../components/Button';
import WaveVisual from '../../components/WaveVisual';
import { COLORS, FONT_BOLD, NAV_BAR_HEIGHT, SETTINGS_SIGNED_OUT_WAVE_VISUAL_COLORS, SMALL_MARGIN, STANDARD_MARGIN, STANDARD_FONT_SIZE, TITLE_FONT, TITLE_FONT_SIZE } from '../../constants';
import { navigate } from '../../util/navigate';

const Container = styled.View`
  flex: 1;
  margin-bottom: ${NAV_BAR_HEIGHT + 2 * STANDARD_MARGIN}px;
  justify-content: space-around;
  width: 100%;
`;

const WaveContainer = styled.View`
  position: relative;
  align-items: center;
`;

const CloudIcon = styled(FontAwesome5)`
  position: absolute;
  top: 47px;
`;

const TextContainer = styled.View`
  align-items: center;
  padding-horizontal: ${STANDARD_MARGIN}px;
`;

const Title = styled.Text`
  text-align: center;
  font-family: ${TITLE_FONT};
  font-size: ${TITLE_FONT_SIZE}px;
  color: ${COLORS.DARK};
  margin-bottom: ${SMALL_MARGIN}px;
`;

const Text = styled.Text`
  text-align: center;
  font-family: ${FONT_BOLD};
  font-size: ${STANDARD_FONT_SIZE}px;
  line-height: ${STANDARD_FONT_SIZE * 1.5}px;
  color: ${COLORS.DARK};
`;

const ButtonContainer = styled.View`
  padding-horizontal: ${STANDARD_MARGIN}px;
  align-items: center;
  width: 100%;
`;

const SignUpButton = styled(PrimaryButton)`
  background-color: ${COLORS.HIGHLIGHTS[3]};
  margin-bottom: ${SMALL_MARGIN}px;
`;

const SettingsSignedOut = () => {

  const onPressSignUp = () => {
    navigate('Sign up');
  };

  const onPressLogIn = () => {
    navigate('Log in');
  };

  return (
    <Container>
      <WaveContainer>
        <WaveVisual isAnimating animationDuration={15000} lineColors={SETTINGS_SIGNED_OUT_WAVE_VISUAL_COLORS} />
        <CloudIcon name='cloud' color={COLORS.BACKGROUND} size={80} solid />
      </WaveContainer>
      <TextContainer>
        <Title>
          Cloud Backups
        </Title>
        <Text>
          Backup your recordings to the cloud and access them from any device.
        </Text>
      </TextContainer>
      <ButtonContainer>
        <SignUpButton 
          text='Sign up'
          onPress={onPressSignUp}
        />
        <SecondaryButton 
          text='Log in'
          onPress={onPressLogIn}
        />
      </ButtonContainer>
    </Container>
  );
};

export default SettingsSignedOut;