import { Platform } from 'react-native';

export const DEVICE_INFO = {
  platform: Platform.OS
};

export const IS_WEB = Platform.OS === 'web';
export const IS_IOS = Platform.OS === 'ios';

export const ANIMATION_USE_NATIVE_DRIVER = !IS_WEB;

export const COLORS = {
  DARK: '#573B3A',
  LIGHT: '#DDC470',
  BACKGROUND: '#E4E0C7',
  HIGHLIGHTS: [
    '#FD6C2D',
    '#016E93',
    '#758C32',
    '#910E75',
    '#FD6C2DAA',
    '#016E93AA',
    '#758C32AA',
    '#910E75AA',
    '#FD6C2D55',
    '#016E9355',
    '#758C3255',
    '#910E7555',
  ]
};

export const FONT = 'BalooTamma2_400Regular';
export const FONT_BOLD = 'BalooTamma2_700Bold';
export const TITLE_FONT = 'Coiny_400Regular';

export const RECORDING_TIME_FONT_SIZE = 64;
export const TITLE_FONT_SIZE = 24;
export const LARGE_FONT_SIZE = 18;
export const STANDARD_FONT_SIZE = 16;
export const SMALL_FONT_SIZE = 14;
export const NAV_BAR_FONT_SIZE = 14;

export const NAV_BAR_ICON_SIZE = 28;

export const HEADER_ICON_SIZE = 20;

export const NAV_BAR_HEIGHT = 80;
export const STANDARD_MARGIN = 30;
export const SMALL_MARGIN = 10;

export const SCREEN_OPTIONS = {
  headerStyle: {
    backgroundColor: COLORS.BACKGROUND,
    borderBottomWidth: 0
  },
  headerTitleAlign: 'center',
  headerTitleStyle: {
    fontFamily: TITLE_FONT,
    color: COLORS.DARK,
    fontSize: TITLE_FONT_SIZE
  }
};

export const DEFAULT_RECORDING_NAME = 'Untitled';

export const NAV_BAR_DATA = [
  {
    name: 'Record',
    icon: 'dot-circle',
    iconStyle: {}
  },
  {
    name: 'Library',
    icon: 'folder-open',
    iconStyle: {
      marginRight: -3,
    }
  },
  {
    name: 'Settings',
    icon: 'cog',
    iconStyle: {}
  }
];

export const WAVE_VISUAL_LINE_THICKNESS = 20;
export const WAVE_VISUAL_AMPLITUDE = 50;

export const RECORD_WAVE_VISUAL_COLORS = [
  COLORS.HIGHLIGHTS[0] + '55',
  COLORS.HIGHLIGHTS[0] + 'AA',
  COLORS.HIGHLIGHTS[0],
  COLORS.HIGHLIGHTS[1],
  COLORS.HIGHLIGHTS[1] + 'AA',
  COLORS.HIGHLIGHTS[1] + '55',
];

export const SETTINGS_SIGNED_OUT_WAVE_VISUAL_COLORS = [
  COLORS.HIGHLIGHTS[3] + '55',
  COLORS.HIGHLIGHTS[3] + '88',
  COLORS.HIGHLIGHTS[3] + 'BB',
  COLORS.HIGHLIGHTS[3],
  COLORS.HIGHLIGHTS[3] + 'BB',
  COLORS.HIGHLIGHTS[3] + '88',
  COLORS.HIGHLIGHTS[3] + '55',
];

export const PROCESSING_WAVE_VISUAL_COLORS = [
  COLORS.HIGHLIGHTS[0] + '11',
  COLORS.HIGHLIGHTS[0] + '33',
  COLORS.HIGHLIGHTS[0] + '55',
  COLORS.HIGHLIGHTS[0] + '77',
  COLORS.HIGHLIGHTS[0] + '99',
  COLORS.HIGHLIGHTS[0] + 'BB',
  COLORS.HIGHLIGHTS[0] + 'DD',
  COLORS.HIGHLIGHTS[0],
  COLORS.HIGHLIGHTS[0] + 'DD',
  COLORS.HIGHLIGHTS[0] + 'BB',
  COLORS.HIGHLIGHTS[0] + '99',
  COLORS.HIGHLIGHTS[0] + '77',
  COLORS.HIGHLIGHTS[0] + '55',
  COLORS.HIGHLIGHTS[0] + '33',
  COLORS.HIGHLIGHTS[0] + '11',
];

export const LIBRARY_EMPTY_WAVE_VISUAL_COLORS = [
  COLORS.LIGHT + '11',
  COLORS.LIGHT + '33',
  COLORS.LIGHT + '55',
  COLORS.LIGHT + '77',
  COLORS.LIGHT + '99',
  COLORS.LIGHT + 'BB',
  COLORS.LIGHT + '99',
  COLORS.LIGHT + '77',
  COLORS.LIGHT + '55',
  COLORS.LIGHT + '33',
  COLORS.LIGHT + '11',
];

export const Loading_WAVE_VISUAL_COLORS = [
  COLORS.DARK + 'AA',
  COLORS.DARK + 'BB',
  COLORS.DARK + 'CC',
  COLORS.DARK + 'DD',
  COLORS.DARK + 'EE',
  COLORS.DARK + 'FF',
  COLORS.DARK + 'EE',
  COLORS.DARK + 'DD',
  COLORS.DARK + 'CC',
  COLORS.DARK + 'BB',
  COLORS.DARK + 'AA',
];

export const SIGN_IN_WAVE_VISUAL_OPACITIES = [
  '55',
  '88',
  'BB',
  'FF',
  'BB',
  '88',
  '55',
];

export const BUTTON_HEIGHT = 40;
export const BUTTON_ICON_SIZE = 18;
export const BUTTON_BORDER_WIDTH = 3;
export const BUTTTON_ICON_MARGIN = 18;

export const TEXT_INPUT_HEIGHT = 40;
export const TEXT_INPUT_BORDER_WIDTH = 3;
export const TEXT_INPUT_HORIZONTAL_PADDING = 20;
export const TEXT_INPUT_PLACEHOLDER_COLOR = '#9D8D81';

export const RECORDING_BUTTON_SIZE = 80;
export const RECORDING_BUTTON_ICON_SIZE = 25;

export const SIGN_IN_RECORD_ICON_SIZE = 200;

// Editor

export const EDITOR_MAX_NUM_PEAKS = 10;
export const EDITOR_MAX_NUM_CLUSTERS = 10;

// Audio visual

export const AUDIO_VISUAL_LINE_WIDTH = 4;
export const AUDIO_VISUAL_LINE_MARGIN = 2;

// Drum picker

export const DRUM_PICKER_BUTTON_HEIGHT = 28;

// Confidence picker

export const CONFIDENCE_PICKER_ARROW_SIZE = 25;

// Recorder

export const RECORDER_STATUSES = {
  READY: 'ready',
  LOADING: 'loading',
  FAILED: 'failed'
};

// Web recorder

export const WEB_RECORDER_TIME_SLICE_MS = 50;
export const WEB_RECORDER_SKIP_MS = 350;

// Library

export const LIBRARY_PLAY_BUTTON_SIZE = 30;
export const LIBRARY_PLAY_ICON_SIZE = 10;
export const LIBRARY_VISUAL_DOT_SIZE = 7;
export const LIBRARY_VISUAL_DOT_MARGIN = 5;
export const LIBRARY_VISUAL_ANIMATION_DURATION = 50;

// Modal

export const MODAL_ANIMATION_DURATION = 200;
export const MODAL_BORDER_RADIUS = 20;

// Storage

export const RECORDINGS_STORAGE_KEY = 'recordings';
export const RECORDING_STORAGE_KEY = 'recording';
export const INPUT_AUDIO_STORAGE_KEY = 'inputAudio';
export const USER_STOARGE_KEY = 'user';

// Drum sounds

export const DRUM_SOUND_RATE = 44100;
export const DRUM_SOUND_CHANNELS = 1;

// Export 

export const EXPORT_FILE_TYPES = {
  WAV: 'WAV',
  MIDI: 'MIDI',
};
export const MIDI_DRUM_START = 36;
export const MIDI_BPM = 120;

// Beatbot

export const BEATBOT_WINDOW_SIZE = 512;
export const BEATBOT_OSAMP = 2;

// API

export const AUTH_TOKEN_ACTION_TYPE = 'authToken/';
export const USER_ACTION_TYPE = 'user/';
export const RECORDING_ACTION_TYPE = 'recording/';
export const FEEDBACK_ACTION_TYPE = 'feedback/';

export const RECORDING_OMITTED_FIELDS = [
  '_id',
  'isBackedUp',
  'isDeleted',
  'beatbotData.outputAudio',
];

// Security

export const MIN_PASSWORD_LENGTH = 4;
export const MAX_PASSWORD_LENGTH = 64;
